
import { IonApp, IonRouterOutlet, createAnimation } from '@ionic/vue';
import { defineComponent } from 'vue';

import Information from '@/components/Information.vue';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    Information
  },
  methods: {
    ...mapActions(['loadNotifications', 'getSettings', 'getTrackingOrder', 'refreshUser', 'loadCart']),

    navAnimation(ElementRef, opts){
      const enterAnimation = createAnimation()
        .addElement(opts.enteringEl)
        .fromTo('opacity', '0', '1')
        .delay(250)
        .duration(250);

      const exitAnimation = createAnimation()
        .addElement(opts.leavingEl)
        .fromTo('opacity', '1', '0')
        .duration(250);

      const rootAnimation = createAnimation();

      rootAnimation.addAnimation([exitAnimation,enterAnimation]);

      return rootAnimation;
    },
  },
  mounted(){
    this.loadNotifications();
    this.getSettings();
    this.getTrackingOrder();
    this.refreshUser();
    this.loadCart();
  }
});
