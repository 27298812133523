import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCDwHcjsSg77uxDoM26FrwdlFqrU3YHeoM",
  authDomain: "docemasunorestaurante-com.firebaseapp.com",
  databaseURL: "https://docemasunorestaurante-com.firebaseio.com",
  projectId: "docemasunorestaurante-com",
  storageBucket: "docemasunorestaurante-com.appspot.com",
  messagingSenderId: "272411008150",
  appId: "1:272411008150:web:120f462056b828eca636b7",
  measurementId: "G-NG2F0C0NF3"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();
firebaseApp.firestore().settings({
  ignoreUndefinedProperties: true,
});
export const auth = firebaseApp.auth();
