// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// Google Analitycs 4

// Funciones Generales
export function GA4_test() {
    console.log('test event');
    gtag('event', 'xyz', { 'debug_mode':true });
}

// Ecommerce
    // Visitas
    export function GA4_view_item(product) {
        switch (product.name) {
            case 'Hamburguesa Madurita':
                gtag('event', 'view_item_madurita', 
                {
                    'currency': 'EUR',
                    'value': product.price,
                    'items': [
                        {
                            'item_id': product.objectID,
                            'item_name': product.name,
                            'item_category': product.category?.name,
                            'price': product.price,
                            'quantity': product.quantity,
                        }
                    ],

                });
                break;

            case 'Hamburguesa Cabrón':
                gtag('event', 'view_item_cabron', 
                {
                    'currency': 'EUR',
                    'value': product.price,
                    'items': [
                        {
                            'item_id': product.objectID,
                            'item_name': product.name,
                            'item_category': product.category?.name,
                            'price': product.price,
                            'quantity': product.quantity,
                        }
                    ],
    
                });
                break;

            case 'Hamburguesa Vulcano':
                gtag('event', 'view_item_vulcano', 
                {
                    'currency': 'EUR',
                    'value': product.price,
                    'items': [
                        {
                            'item_id': product.objectID,
                            'item_name': product.name,
                            'item_category': product.category?.name,
                            'price': product.price,
                            'quantity': product.quantity,
                        }
                    ],
    
                });
                break;

            case 'Hamburguesa Gourmet':
                gtag('event', 'view_item_gourmet', 
                {
                    'currency': 'EUR',
                    'value': product.price,
                    'items': [
                        {
                            'item_id': product.objectID,
                            'item_name': product.name,
                            'item_category': product.category?.name,
                            'price': product.price,
                            'quantity': product.quantity,
                        }
                    ],
    
                });
                break;

            case 'Hamburguesa La Poll@':
                gtag('event', 'view_item_lapolla', 
                {
                    'currency': 'EUR',
                    'value': product.price,
                    'items': [
                        {
                            'item_id': product.objectID,
                            'item_name': product.name,
                            'item_category': product.category?.name,
                            'price': product.price,
                            'quantity': product.quantity,
                        }
                    ],
    
                });
                break;
        
            default:
                gtag('event', 'view_item', 
                {
                    'currency': 'EUR',
                    'value': product.price,
                    'items': [
                        {
                            'item_id': product.objectID,
                            'item_name': product.name,
                            'item_category': product.category?.name,
                            'price': product.price,
                            'quantity': product.quantity,
                        }
                    ],
    
                });
                break;
        }

    }

    export function GA4_view_promotion(product) {
        gtag('event', 'view_promotion', 
            {
                'promotion_id': product.objectID,
                'promotion_name': product.name,
                'items': [
                    {
                        'item_id': product.objectID,
                        'item_name': product.name,
                        'item_category': product.type,
                    }
                ],
            });
    }

    export function GA4_view_menu(product) {
        gtag('event', 'view_menu', 
            {
                'currency': 'EUR',
                'value': product.price,
                'items': [
                    {
                        'item_id': product.objectID,
                        'item_name': product.name,
                        'item_category': product.category?.name,
                        'price': product.price,
                        'quantity': product.quantity,
                    }
                ],

            });
    }

    export function GA4_view_reward(product) {
        gtag('event', 'view_reward', 
            {
                'currency': 'EUR',
                'value': product.price,
                'items': [
                    {
                        'item_id': product.objectID,
                        'item_name': product.name,
                        'item_category': product.category?.name,
                        'price': product.price,
                        'quantity': product.quantity,
                    }
                ],

            });
    }

    export function GA4_view_cart(cart) {
        const cartItems = [];

        cart.items?.forEach(element => {
            cartItems.push(
                {
                    'item_id': element.objectID,
                    'item_name': element.name,
                    'item_category': element.category?.name,
                    'price': element.price,
                    'quantity': element.quantity,
                }
            )
        });

        gtag('event', 'view_cart', 
            {
                'currency': 'EUR',
                'value': cart.total,
                'items': cartItems,
            });
    }

    // Usuario
    export function GA4_login(user) {
        gtag('event', 'login', 
            {
                'method': user.provider,
            });
    }

    export function GA4_sign_up(user) {
        gtag('event', 'sign_up', 
            {
                'method': user.provider,
            });
    }

    // Carrito
    export function GA4_add_to_cart_item(product) {
        gtag('event', 'add_to_cart_item', 
            {
                'currency': 'EUR',
                'value': product.price,
                'items': [
                    {
                        'item_id': product.objectID,
                        'item_name': product.name,
                        'item_category': product.category?.name,
                        'price': product.price,
                        'quantity': product.quantity,
                    }
                ],

            });
    }

    export function GA4_add_to_cart_promotion(product) {
        gtag('event', 'add_to_cart_promotion', 
            {
                'currency': 'EUR',
                'value': product.price,
                'items': [
                    {
                        'item_id': product.objectID,
                        'item_name': product.name,
                        'item_category': product.category?.name,
                        'price': product.price,
                        'quantity': product.quantity,
                    }
                ],

            });
    }

    export function GA4_add_to_cart_menu(product) {
        gtag('event', 'add_to_cart_menu', 
            {
                'currency': 'EUR',
                'value': product.price,
                'items': [
                    {
                        'item_id': product.objectID,
                        'item_name': product.name,
                        'item_category': product.category?.name,
                        'price': product.price,
                        'quantity': product.quantity,
                    }
                ],

            });
    }

    export function GA4_add_to_cart_reward(product) {
        gtag('event', 'add_to_cart_reward', 
            {
                'currency': 'EUR',
                'value': product.price,
                'items': [
                    {
                        'item_id': product.objectID,
                        'item_name': product.name,
                        'item_category': product.category?.name,
                        'price': product.price,
                        'quantity': product.quantity,
                    }
                ],

            });
    }

    export function GA4_remove_from_cart_item(product) {
        gtag('event', 'remove_from_cart_item', 
            {
                'currency': 'EUR',
                'value': product.price,
                'items': [
                    {
                        'item_id': product.objectID,
                        'item_name': product.name,
                        'item_category': product.category?.name,
                        'price': product.price,
                        'quantity': product.quantity,
                    }
                ],

            });
    }

    export function GA4_remove_from_cart_promotion(product) {
        gtag('event', 'remove_from_cart_promotion', 
            {
                'currency': 'EUR',
                'value': product.price,
                'items': [
                    {
                        'item_id': product.objectID,
                        'item_name': product.name,
                        'item_category': product.category?.name,
                        'price': product.price,
                        'quantity': product.quantity,
                    }
                ],

            });
    }

    export function GA4_remove_from_cart_menu(product) {
        gtag('event', 'remove_from_cart_menu', 
            {
                'currency': 'EUR',
                'value': product.price,
                'items': [
                    {
                        'item_id': product.objectID,
                        'item_name': product.name,
                        'item_category': product.category?.name,
                        'price': product.price,
                        'quantity': product.quantity,
                    }
                ],

            });
    }

    export function GA4_remove_from_cart_reward(product) {
        gtag('event', 'remove_from_cart_reward', 
            {
                'currency': 'EUR',
                'value': product.price,
                'items': [
                    {
                        'item_id': product.objectID,
                        'item_name': product.name,
                        'item_category': product.category?.name,
                        'price': product.price,
                        'quantity': product.quantity,
                    }
                ],

            });
    }
    
    // Compra
    export function GA4_begin_checkout(cart) {
        const cartItems = [];

        cart.items?.forEach(element => {
            cartItems.push(
                {
                    'item_id': element.objectID,
                    'item_name': element.name,
                    'item_category': element.category?.name,
                    'price': element.price,
                    'quantity': element.quantity,
                }
            )
        });

        gtag('event', 'begin_checkout', 
            {
                'currency': 'EUR',
                'value': cart.total,
                'coupon': cart.coupon?.name,
                'items': cartItems,
            });
    }

    export function GA4_purchase(order) {
        const orderItems = [];

        order.items?.forEach(element => {
            orderItems.push(
                {
                    'item_id': element.objectID,
                    'item_name': element.name,
                    'item_category': element.category?.name,
                    'price': element.price,
                    'quantity': element.quantity,
                }
            )
        });

        gtag('event', 'purchase', 
            {
                'currency': 'EUR',
                'transaction_id': order.objectID,
                'value': order.total,
                'coupon': order.coupon?.name,
                'items': orderItems,
                'shipping': order.shipping,
                'tax': order.tax,
            });
    }
