import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, {
            slot: "bottom",
            class: "tabar hide-lg hide-xl"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "inicio",
                href: "/restaurant/home"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { src: "/assets/img/uicons/fi-rr-home.svg" }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Inicio")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "promotions",
                href: "/restaurant/promotions"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { src: "/assets/img/uicons/fi-rr-badge-percent.svg" }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Ofertas")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "carta",
                href: "/restaurant/dishes"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { src: "/assets/img/uicons/fi-rr-restaurant.svg" }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Carta")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "desayunos",
                href: "/restaurant/my-account"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { src: "/assets/img/uicons/fi-rr-user.svg" }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Cuenta")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "cart",
                href: "/restaurant/cart"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { src: "/assets/img/uicons/fi-rr-shopping-bag.svg" }),
                  (_ctx.cart && _ctx.cart.items && _ctx.cart.items.length)
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 0,
                        class: "cart-counter",
                        mode: "ios"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.cart.items.length), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Pedido")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}