import OneSignal from 'onesignal-cordova-plugin';
import { db } from '@/firebaseConfig';
import { store } from './store';

// Call this function when your app starts
export function OneSignalInit(){
  // Uncomment to set OneSignal device logging to VERBOSE  
  //OneSignal.setLogLevel(6, 0);

  // NOTE: Update the setAppId value below with your OneSignal AppId.
  OneSignal.setAppId("278534cc-b75a-438d-a6fe-4e70fd0642d8");

  // iOS - Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      console.log("User accepted notifications: " + accepted);
  });

  // ACTUALIZAR
  OneSignal.addSubscriptionObserver(function(data) {

    const user = store.state.user;

    if(user && data.to.isSubscribed){
      user.playerID = data.to.userId;
      db.collection('users').doc(user.uid).update(user);
      OneSignal.setExternalUserId(user.uid);
      OneSignal.setSMSNumber(user.phone);
    }
  });

  OneSignal.setNotificationOpenedHandler(function(jsonData) {
      const additionalData: any = jsonData.notification.additionalData;
      if(additionalData['url']){
        if(additionalData['external']){
          window.open(additionalData['url'], '_system');
        } else {
          window.location.href = additionalData['url'];
        }
      }
  });
}