/* eslint-disable no-case-declarations */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { SHA256 } from 'crypto-js';

// Facebook API

// Funciones Generales
export async function FB_PageView(user) {

    const fbp = getFbBrowserID();
    const fbc = getFbClickID();

    const date = (new Date().getTime() / 1000).toFixed(0);
    const ipResp = await fetch('https://api.ipify.org/?format=json')
      .then(response => response.json())
      .then(data => {return data});
    

    const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
            {
                "data": [
                    {
                    "action_source": "website",
                    "event_source_url": window.location.href,
                    "event_id": date,
                    "event_name": "PageView",
                    "event_time": date,

                    "user_data": {
                        "client_ip_address": ipResp.ip,
                        "client_user_agent": navigator.userAgent,
                        "fbp": fbp,
                        "fbc": fbc,
    
                        // Si el usuario está logueado
                        ...(user && {
                        "em": SHA256(user.email).toString(),
                        "fn": SHA256(user.firstName).toString(),
                        "ln": SHA256(user.lastName).toString(),
                        "ph": SHA256(user.phone).toString(),
                        "external_id": SHA256(user.uid).toString(),
                        })
                    }
                    }
                ],

            }, 

            )
        });

        window.fbq('track', 'ViewContent', {
            "action_source": "website",
            "event_source_url": window.location.href,
            "event_id": date,
            "event_name": "PageView",
            "event_time": date,

            "client_ip_address": ipResp.ip,
            "client_user_agent": navigator.userAgent,
            "fbp": fbp,
            "fbc": fbc,

            // Si el usuario está logueado
            ...(user && {
                "em": SHA256(user.email).toString(),
                "fn": SHA256(user.firstName).toString(),
                "ln": SHA256(user.lastName).toString(),
                "ph": SHA256(user.phone).toString(),
                "external_id": SHA256(user.uid).toString(),
            }),
          },
          {event_id: date})
        const content = await rawResponse.json();
}

// Ecommerce
    // Visitas
    export async function FB_view_item(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

          switch (product.name) {
            case 'Hamburguesa La Poll@':
                const rawResponse6 = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            "data": [
                                {
                                "action_source": "website",
                                "event_source_url": window.location.href,
                                "event_id": date,
                                "event_name": "ViewContent_LaPolla",
                                "event_time": date,
                                
                                "content_ids": [product.objectID],
                                "content_type": "product",
                                "content_category": product.category?.name,
                                "content_name": product.name,
                                "value": product.price,
                                "currency": "EUR",
        
                                "user_data": {
                                    "client_ip_address": ipResp.ip,
                                    "client_user_agent": navigator.userAgent,
                                    "fbp": fbp,
                                    "fbc": fbc,
                
                                    // Si el usuario está logueado
                                    ...(user && {
                                    "em": SHA256(user.email).toString(),
                                    "fn": SHA256(user.firstName).toString(),
                                    "ln": SHA256(user.lastName).toString(),
                                    "ph": SHA256(user.phone).toString(),
                                    "external_id": SHA256(user.uid).toString(),
                                    })
                                }
                                }
                            ],
            
                        }, 
        
                        )
                    });
        
                    window.fbq('trackCustom', 'ViewContent_LaPolla', {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "ViewContent_LaPolla",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",
        
                        "client_ip_address": ipResp.ip,
                        "client_user_agent": navigator.userAgent,
                        "fbp": fbp,
                        "fbc": fbc,
        
                        // Si el usuario está logueado
                        ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                        }),
        
                      },
                      {event_id: date})
                    const content6 = await rawResponse6.json();
                break;

            case 'Hamburguesa Madurita':
                const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            "data": [
                                {
                                "action_source": "website",
                                "event_source_url": window.location.href,
                                "event_id": date,
                                "event_name": "ViewContent_Madurita",
                                "event_time": date,
                                
                                "content_ids": [product.objectID],
                                "content_type": "product",
                                "content_category": product.category?.name,
                                "content_name": product.name,
                                "value": product.price,
                                "currency": "EUR",
        
                                "user_data": {
                                    "client_ip_address": ipResp.ip,
                                    "client_user_agent": navigator.userAgent,
                                    "fbp": fbp,
                                    "fbc": fbc,
                
                                    // Si el usuario está logueado
                                    ...(user && {
                                    "em": SHA256(user.email).toString(),
                                    "fn": SHA256(user.firstName).toString(),
                                    "ln": SHA256(user.lastName).toString(),
                                    "ph": SHA256(user.phone).toString(),
                                    "external_id": SHA256(user.uid).toString(),
                                    })
                                }
                                }
                            ],
            
                        }, 
        
                        )
                    });
        
                    window.fbq('trackCustom', 'ViewContent_Madurita', {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "ViewContent_Madurita",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",
        
                        "client_ip_address": ipResp.ip,
                        "client_user_agent": navigator.userAgent,
                        "fbp": fbp,
                        "fbc": fbc,
        
                        // Si el usuario está logueado
                        ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                        }),
        
        
                      },
                      {event_id: date})
                    const content = await rawResponse.json();
                break;

            case 'Hamburguesa Cabrón':
                const rawResponse2 = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            "data": [
                                {
                                "action_source": "website",
                                "event_source_url": window.location.href,
                                "event_id": date,
                                "event_name": "ViewContent_Cabron",
                                "event_time": date,
                                
                                "content_ids": [product.objectID],
                                "content_type": "product",
                                "content_category": product.category?.name,
                                "content_name": product.name,
                                "value": product.price,
                                "currency": "EUR",

                                "user_data": {
                                    "client_ip_address": ipResp.ip,
                                    "client_user_agent": navigator.userAgent,
                                    "fbp": fbp,
                                    "fbc": fbc,
                
                                    // Si el usuario está logueado
                                    ...(user && {
                                    "em": SHA256(user.email).toString(),
                                    "fn": SHA256(user.firstName).toString(),
                                    "ln": SHA256(user.lastName).toString(),
                                    "ph": SHA256(user.phone).toString(),
                                    "external_id": SHA256(user.uid).toString(),
                                    })
                                }
                                }
                            ],
            
                        }, 

                        )
                    });

                    window.fbq('trackCustom', 'ViewContent_Cabron', {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "ViewContent_Cabron",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "client_ip_address": ipResp.ip,
                        "client_user_agent": navigator.userAgent,
                        "fbp": fbp,
                        "fbc": fbc,

                        // Si el usuario está logueado
                        ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                        }),


                    },
                    {event_id: date})
                    const content2 = await rawResponse2.json();
                break;

            case 'Hamburguesa Vulcano':
                const rawResponse3 = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            "data": [
                                {
                                "action_source": "website",
                                "event_source_url": window.location.href,
                                "event_id": date,
                                "event_name": "ViewContent_Vulcano",
                                "event_time": date,
                                
                                "content_ids": [product.objectID],
                                "content_type": "product",
                                "content_category": product.category?.name,
                                "content_name": product.name,
                                "value": product.price,
                                "currency": "EUR",

                                "user_data": {
                                    "client_ip_address": ipResp.ip,
                                    "client_user_agent": navigator.userAgent,
                                    "fbp": fbp,
                                    "fbc": fbc,
                
                                    // Si el usuario está logueado
                                    ...(user && {
                                    "em": SHA256(user.email).toString(),
                                    "fn": SHA256(user.firstName).toString(),
                                    "ln": SHA256(user.lastName).toString(),
                                    "ph": SHA256(user.phone).toString(),
                                    "external_id": SHA256(user.uid).toString(),
                                    })
                                }
                                }
                            ],
            
                        }, 

                        )
                    });

                    window.fbq('trackCustom', 'ViewContent_Vulcano', {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "ViewContent_Vulcano",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "client_ip_address": ipResp.ip,
                        "client_user_agent": navigator.userAgent,
                        "fbp": fbp,
                        "fbc": fbc,

                        // Si el usuario está logueado
                        ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                        }),


                    },
                    {event_id: date})
                    const content3 = await rawResponse3.json();
                break;

            case 'Hamburguesa Gourmet':
                const rawResponse4 = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            "data": [
                                {
                                "action_source": "website",
                                "event_source_url": window.location.href,
                                "event_id": date,
                                "event_name": "ViewContent_Gourmet",
                                "event_time": date,
                                
                                "content_ids": [product.objectID],
                                "content_type": "product",
                                "content_category": product.category?.name,
                                "content_name": product.name,
                                "value": product.price,
                                "currency": "EUR",

                                "user_data": {
                                    "client_ip_address": ipResp.ip,
                                    "client_user_agent": navigator.userAgent,
                                    "fbp": fbp,
                                    "fbc": fbc,
                
                                    // Si el usuario está logueado
                                    ...(user && {
                                    "em": SHA256(user.email).toString(),
                                    "fn": SHA256(user.firstName).toString(),
                                    "ln": SHA256(user.lastName).toString(),
                                    "ph": SHA256(user.phone).toString(),
                                    "external_id": SHA256(user.uid).toString(),
                                    })
                                }
                                }
                            ],
            
                        }, 

                        )
                    });

                    window.fbq('trackCustom', 'ViewContent_Gourmet', {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "ViewContent_Gourmet",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "client_ip_address": ipResp.ip,
                        "client_user_agent": navigator.userAgent,
                        "fbp": fbp,
                        "fbc": fbc,

                        // Si el usuario está logueado
                        ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                        }),


                    },
                    {event_id: date})
                    const content4 = await rawResponse4.json();
                break;
        
            default:
                const rawResponse5 = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            "data": [
                                {
                                "action_source": "website",
                                "event_source_url": window.location.href,
                                "event_id": date,
                                "event_name": "ViewContent",
                                "event_time": date,
                                
                                "content_ids": [product.objectID],
                                "content_type": "product",
                                "content_category": product.category?.name,
                                "content_name": product.name,
                                "value": product.price,
                                "currency": "EUR",

                                "user_data": {
                                    "client_ip_address": ipResp.ip,
                                    "client_user_agent": navigator.userAgent,
                                    "fbp": fbp,
                                    "fbc": fbc,
                
                                    // Si el usuario está logueado
                                    ...(user && {
                                    "em": SHA256(user.email).toString(),
                                    "fn": SHA256(user.firstName).toString(),
                                    "ln": SHA256(user.lastName).toString(),
                                    "ph": SHA256(user.phone).toString(),
                                    "external_id": SHA256(user.uid).toString(),
                                    })
                                }
                                }
                            ],
            
                        }, 

                        )
                    });

                    window.fbq('track', 'ViewContent', {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "ViewContent",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "client_ip_address": ipResp.ip,
                        "client_user_agent": navigator.userAgent,
                        "fbp": fbp,
                        "fbc": fbc,

                        // Si el usuario está logueado
                        ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                        }),


                    },
                    {event_id: date})
                    const content5 = await rawResponse5.json();
                break;
        }

        
    }

    export async function FB_view_promotion(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "ViewContent_Promotion",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'ViewContent_Promotion', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "ViewContent_Promotion",
                "event_time": date,
                
                "content_ids": [product.objectID],
                "content_type": "product",
                "content_category": product.category?.name,
                "content_name": product.name,
                "value": product.price,
                "currency": "EUR",

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    export async function FB_view_menu(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "ViewContent_Menu",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'ViewContent_Menu', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "ViewContent_Menu",
                "event_time": date,
                
                "content_ids": [product.objectID],
                "content_type": "product",
                "content_category": product.category?.name,
                "content_name": product.name,
                "value": product.price,
                "currency": "EUR",

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    export async function FB_view_reward(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "ViewContent_Reward",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'ViewContent_Reward', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "ViewContent_Reward",
                "event_time": date,
                
                "content_ids": [product.objectID],
                "content_type": "product",
                "content_category": product.category?.name,
                "content_name": product.name,
                "value": product.price,
                "currency": "EUR",

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }
    

    export async function FB_view_cart(user, cart) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        
        const cartItems = [];
        const ids = [];

        cart.items?.forEach(element => {
            cartItems.push(
                {
                    'id': element.objectID,
                    'name': element.name,
                    'category': element.category?.name,
                    'price': element.price,
                    'quantity': element.quantity,
                }
            )

            ids.push(element.objectID);
        });

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "ViewContent_Cart",
                        "event_time": date,
                        
                        "content_ids": ids,
                        "content_type": "product",
                        "content_category": "Cart",
                        "content_name": "Cart",
                        "value": cart.total,
                        "currency": "EUR",
                        "contents": cartItems,

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'ViewContent_Cart', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "ViewContent_Cart",
                "event_time": date,
                
                "content_ids": ids,
                "content_type": "product",
                "content_category": "Cart",
                "content_name": "Cart",
                "value": cart.total,
                "currency": "EUR",
                "contents": cartItems,

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    // Usuario
    export async function FB_login(user) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();
    
        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        
    
        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "Login",
                        "event_time": date,

                        "content_name": "Login",
                        "status": true,
                        "value": 0,
                        "currency": "EUR",
    
                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 
    
                )
            });
    
            window.fbq('trackCustom', 'Login', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "Login",
                "event_time": date,

                "content_name": "Login",
                "status": true,
                "value": 0,
                "currency": "EUR",
    
                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,
    
                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),
    

              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    export async function FB_sign_up(user) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();
    
        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        
    
        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "CompleteRegistration",
                        "event_time": date,

                        "content_name": "Sign Up",
                        "status": true,
                        "value": 0,
                        "currency": "EUR",
    
                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 
    
                )
            });
    
            window.fbq('track', 'CompleteRegistration', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "CompleteRegistration",
                "event_time": date,

                "content_name": "Sign Up",
                "status": true,
                "value": 0,
                "currency": "EUR",
    
                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,
    
                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),
    

              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    // Carrito
    export async function FB_add_to_cart_item(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "AddToCartItem",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'AddToCartItem', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "AddToCartItem",
                "event_time": date,
                
                "content_ids": [product.objectID],
                "content_type": "product",
                "content_category": product.category?.name,
                "content_name": product.name,
                "value": product.price,
                "currency": "EUR",

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    export async function FB_add_to_cart_promotion(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "AddToCartPromotion",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'AddToCartPromotion', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "AddToCartPromotion",
                "event_time": date,
                
                "content_ids": [product.objectID],
                "content_type": "product",
                "content_category": product.category?.name,
                "content_name": product.name,
                "value": product.price,
                "currency": "EUR",

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    export async function FB_add_to_cart_menu(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "AddToCartMenu",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'AddToCartMenu', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "AddToCartMenu",
                "event_time": date,
                
                "content_ids": [product.objectID],
                "content_type": "product",
                "content_category": product.category?.name,
                "content_name": product.name,
                "value": product.price,
                "currency": "EUR",

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    export async function FB_add_to_cart_reward(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "AddToCartReward",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'AddToCartReward', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "AddToCartReward",
                "event_time": date,
                
                "content_ids": [product.objectID],
                "content_type": "product",
                "content_category": product.category?.name,
                "content_name": product.name,
                "value": product.price,
                "currency": "EUR",

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    export async function FB_remove_from_cart_item(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "RemoveFromCart_Item",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'RemoveFromCart_Item', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "RemoveFromCart_Item",
                "event_time": date,
                
                "content_ids": [product.objectID],
                "content_type": "product",
                "content_category": product.category?.name,
                "content_name": product.name,
                "value": product.price,
                "currency": "EUR",

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    export async function FB_remove_from_cart_promotion(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "RemoveFromCart_Promotion",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'RemoveFromCart_Promotion', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "RemoveFromCart_Promotion",
                "event_time": date,
                
                "content_ids": [product.objectID],
                "content_type": "product",
                "content_category": product.category?.name,
                "content_name": product.name,
                "value": product.price,
                "currency": "EUR",

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    export async function FB_remove_from_cart_menu(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "RemoveFromCart_Menu",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'RemoveFromCart_Menu', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "RemoveFromCart_Menu",
                "event_time": date,
                
                "content_ids": [product.objectID],
                "content_type": "product",
                "content_category": product.category?.name,
                "content_name": product.name,
                "value": product.price,
                "currency": "EUR",

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    export async function FB_remove_from_cart_reward(user, product) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "RemoveFromCart_Reward",
                        "event_time": date,
                        
                        "content_ids": [product.objectID],
                        "content_type": "product",
                        "content_category": product.category?.name,
                        "content_name": product.name,
                        "value": product.price,
                        "currency": "EUR",

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('trackCustom', 'RemoveFromCart_Reward', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "RemoveFromCart_Reward",
                "event_time": date,
                
                "content_ids": [product.objectID],
                "content_type": "product",
                "content_category": product.category?.name,
                "content_name": product.name,
                "value": product.price,
                "currency": "EUR",

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }
    
    // Compra
    export async function FB_begin_checkout(user, cart) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        
        const cartItems = [];
        const ids = [];

        cart.items?.forEach(element => {
            cartItems.push(
                {
                    'id': element.objectID,
                    'name': element.name,
                    'category': element.category?.name,
                    'price': element.price,
                    'quantity': element.quantity,
                }
            )

            ids.push(element.objectID);
        });

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "InitiateCheckout",
                        "event_time": date,
                        
                        "content_ids": ids,
                        "content_category": "Checkout",
                        "value": cart.total,
                        "currency": "EUR",
                        "contents": cartItems,
                        "num_items": cartItems.length,

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('track', 'InitiateCheckout', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "InitiateCheckout",
                "event_time": date,
                
                "content_ids": ids,
                "content_category": "Checkout",
                "value": cart.total,
                "currency": "EUR",
                "contents": cartItems,
                "num_items": cartItems.length,

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }

    export async function FB_purchase(user, cart) {

        const fbp = getFbBrowserID();
        const fbc = getFbClickID();

        const date = (new Date().getTime() / 1000).toFixed(0);
        const ipResp = await fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {return data});
        
        const cartItems = [];
        const ids = [];

        cart.items?.forEach(element => {
            cartItems.push(
                {
                    'id': element.objectID,
                    'name': element.name,
                    'category': element.category?.name,
                    'price': element.price,
                    'quantity': element.quantity,
                }
            )

            ids.push(element.objectID);
        });

        const rawResponse = await fetch('https://graph.facebook.com/v16.0/310608793769009/events?access_token=EAAU5WHhku5YBAPvOEn5J0X1aOWDfRkBIbHlJTahtsT0z9S8WahG6TfsnD8vFdcGrUdrlzHEMr6207MS1gVJZCU2NZCgj5sy9ZA62OUZBZBbF1zZBL8l1lnYQNmIFK5yJntXeeeZBo7neBT7vvCZCkK5qUIWjJmLxI2y60PzZCSZCdWktm8cRhBzsqlsLXfvxQy29MZD', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "data": [
                        {
                        "action_source": "website",
                        "event_source_url": window.location.href,
                        "event_id": date,
                        "event_name": "Purchase",
                        "event_time": date,
                        
                        "content_ids": ids,
                        "content_name": "Purchase",
                        "content_category": "Purchase",
                        "value": cart.total,
                        "currency": "EUR",
                        "contents": cartItems,
                        "num_items": cartItems.length,

                        "user_data": {
                            "client_ip_address": ipResp.ip,
                            "client_user_agent": navigator.userAgent,
                            "fbp": fbp,
                            "fbc": fbc,
        
                            // Si el usuario está logueado
                            ...(user && {
                            "em": SHA256(user.email).toString(),
                            "fn": SHA256(user.firstName).toString(),
                            "ln": SHA256(user.lastName).toString(),
                            "ph": SHA256(user.phone).toString(),
                            "external_id": SHA256(user.uid).toString(),
                            })
                        }
                        }
                    ],
    
                }, 

                )
            });

            window.fbq('track', 'Purchase', {
                "action_source": "website",
                "event_source_url": window.location.href,
                "event_id": date,
                "event_name": "Purchase",
                "event_time": date,
                
                "content_ids": ids,
                "content_name": "Purchase",
                "content_category": "Purchase",
                "value": cart.total,
                "currency": "EUR",
                "contents": cartItems,
                "num_items": cartItems.length,

                "client_ip_address": ipResp.ip,
                "client_user_agent": navigator.userAgent,
                "fbp": fbp,
                "fbc": fbc,

                // Si el usuario está logueado
                ...(user && {
                    "em": SHA256(user.email).toString(),
                    "fn": SHA256(user.firstName).toString(),
                    "ln": SHA256(user.lastName).toString(),
                    "ph": SHA256(user.phone).toString(),
                    "external_id": SHA256(user.uid).toString(),
                }),


              },
              {event_id: date})
            const content = await rawResponse.json();
    }


    // OTROS
function getFbBrowserID() {
    const result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
    if (!(result && result[1])) {
      return null;
    }
    return result[1];
  }


  function getFbClickID() {
    const result = /_fbc=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
    if (!(result && result[1])) {
      return null;
    }
    return result[1];

  }
