<template>
    <ion-menu side="end" menu-id="info" :swipeGesture="false" content-id="main-content">
        <ion-content>


            <ion-header class="ion-no-border container" id="header">
                <ion-toolbar color="transparent" class="header-item">

                <ion-title>Información</ion-title>

                    <ion-buttons slot="end">
                        <ion-button @click="close()">
                            <ion-icon src="/assets/img/uicons/fi-rr-cross-small.svg" color="dark" slot="icon-only"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header>

            <div class="inner-container">


                <div class="mt-lg pb-xs">
                    <p class="pb-xs">Dirección</p>
                    <h5><a href="https://g.page/docemasunogijon?share" target="_blank">C, Av. Manuel Llaneza, 13, bajo, 33205 Gijón, Asturias</a></h5>
                </div>

                <div class="mt-sm pb-xs">
                    <p class="pb-xs">Teléfono</p>
                    <h5><a href="tel:984182874" target="_blank">984 18 28 74</a></h5>
                </div>

                <div class="mt-sm pb-xs">
                    <p class="pb-xs">Horarios</p>
                    <h5><a href="https://g.page/docemasunogijon?share" target="_blank">Consultar</a></h5>
                </div>

                <ion-button shape="round" class="mt-md" expand="block" href="https://wa.link/b7eh83" target="_blank">
                    <ion-label>Reservas</ion-label>
                </ion-button>

                <ion-footer class="ion-no-border" id="info-footer">
                    <ion-toolbar color="transparent">
                        <ion-buttons slot="end">
                            <ion-button>
                                <ion-label>v5.0.0</ion-label>
                            </ion-button>
                            <ion-button href="mailto:soporte@evolbe.systems">
                                <ion-label>Soporte</ion-label>
                            </ion-button>
                            <ion-button href="mailto:info@docemasunorestaurante.com">
                                <ion-label>Sugerencias</ion-label>
                            </ion-button>
                        </ion-buttons>
                    </ion-toolbar>
                </ion-footer>

            </div>
        </ion-content>
    </ion-menu>
</template>

<script>
import { IonContent, IonToolbar, IonLabel, IonMenu, IonTitle, IonIcon, IonButton, IonButtons, IonHeader, IonFooter, menuController} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InformationPage',
  components: {
    IonContent, IonMenu, IonTitle, IonIcon, IonButton, IonButtons, IonHeader, IonFooter, IonToolbar, IonLabel,
  },
  methods: {
   close(){
        menuController.close();
    }
  }
});
</script>

<style scoped>
    
    .inner-container{
        padding: 0 32px;
    }


    /* Header */
    #header{
        overflow: hidden;
        background: transparent;
        border: 0;
        overflow: visible;
    }

    .header-item{
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --inner-padding-top: 0;
        --inner-padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        display: flex;
        height: 48px;
        overflow: visible;
        margin: 24px auto;
        padding: 0;
    }

    .logo{
        display: block;
        cursor: pointer;
        width: auto;
        height: 40px;
        margin: 0;
    }

    .logo img{
        height: 100%;
    }

    #info-footer{
        position: absolute;
        bottom: 0;
    }

    #info-footer ion-toolbar{
        width: calc(100% - 48px);
    }

    #info-footer ion-toolbar ion-button ion-label{
        font-weight: 300 !important;
    }
</style>