/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import { auth, db } from '@/firebaseConfig';
import { loadingController, toastController } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import router from '../router/index';

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import { OneSignalInit } from '../OneSignal';
import { eachDayOfInterval, format, getDay, getHours } from 'date-fns';
import * as Sentry from '@sentry/browser';

import XLSX from 'xlsx/dist/xlsx.full.min.js';
import { GA4_add_to_cart_item, GA4_add_to_cart_menu, GA4_add_to_cart_promotion, GA4_add_to_cart_reward, GA4_login, GA4_sign_up } from '@/utils/GoogleAnalitycs';
import { FB_add_to_cart_item, FB_add_to_cart_menu, FB_add_to_cart_promotion, FB_add_to_cart_reward, FB_login, FB_sign_up } from '@/utils/FacebookAPI';
import { add } from 'date-fns/esm';
import { email } from '../utils/i18n-validators';

export const store = createStore({
    state: {
        pages: undefined,
        settings: undefined,
        achievements: [],

        dishes: [],
        dish: {},
        dishesCategories: [],
        promotions: [],
        promotion: {},
        todaysMenu: undefined,
        rewards: [],
        reward: {},

        desayunos: [],

        cart: undefined,
        coupons: undefined,
        privateCoupons: undefined,

        trackingOrder: undefined,

        user: undefined,
        userOrders: [],
        userPoints: [],

        returnURL: '/',
        bigBanner: true,
    },
    mutations: {
        // BLOCKS
        setPages(state, payload){
            state.pages = payload;
        },

        // SETTINGS
        setSettings(state, payload){
            state.settings = payload;
        },
        setAchievements(state, payload){
            state.achievements = payload
        },

        // RESTAURANT
        setDishes(state, payload){
            state.dishes = payload;
        },
        setDish(state, payload){
            state.dish = payload
        },

        setDishesCategories(state, payload){
            state.dishesCategories = payload
        },

        setPromotions(state, payload){
            state.promotions = payload
        },
        setPromotion(state, payload){
            state.promotion = payload
        },

        setTodaysMenu(state, payload){
            state.todaysMenu = payload
        },

        setRewards(state, payload){
            state.rewards = payload;
        },
        setReward(state, payload){
            state.reward = payload
        },

        // DESAYUNOS
        setDesayunos(state, payload){
            state.desayunos = payload
        },

        // COUPONS
        setCoupons(state, payload){
            state.coupons = payload;
        },
        setPrivateCoupons(state, payload){
            state.privateCoupons = payload
        },

        // USER
        setUser(state, payload){
            state.user = payload
        },
        setUserOrders(state, payload){
            state.userOrders = payload
        },
        setUserPoints(state, payload){
            state.userPoints = payload
        },


        // CART
        setCart(state, payload){
            state.cart = payload;
            localStorage.setItem('cart', JSON.stringify(undefined));
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },

        setTrackingOrder(state, payload){
            state.trackingOrder = payload;
        },

        // OTHERS
        setReturnURL(state, payload){
            state.returnURL = payload
        },
        setBigBanner(state, payload){
            state.bigBanner = payload
        },
    },
    actions: {

        // BLOCKS
        async getPages({commit, dispatch}){
            if(this.state.pages === undefined){
                const data = {};
                await db.collection('/pages').get().then(res => {
                    // Convert to Object
                    res.forEach(item => {
                        data[item.id] = {...item.data()}
                    })
                })
                .catch(err => {
                    console.log(err);
                });
                commit('setPages', data);
            }
        },


        // SETTINGS
        async getSettings({commit, dispatch}){
            if(this.state.settings === undefined){
                const data = {};
                await db.collection('/settings').get().then(res => {
                    res.forEach(item => {
                        const e = {
                            ...item.data()
                        }
                        data[item.id] = e;
                    })
                })
                .catch(err => {
                    console.log(err);
                });

                commit('setSettings', data);
                return data;
            }
        },

        async getAchievements({ commit, dispatch }) {
            const data = [];
            const querySnapshot = await db
              .collection('/settings/points/achievements')
              .orderBy('points', 'asc')
              .get();
          
            querySnapshot.forEach((item) => {
              const e = {
                objectID: item.id,
                ...item.data(),
              };
              data.push(e);
            });
          
            commit('setAchievements', data);
            return data;
        },

        // RESTAURANT
        // Dishes
        async getDishes({commit, dispatch}){
            if(!this.state.dishes.length){
                const data = [];
                await db.collection('/evolbe-modules/restaurant/dishes').where('active', '==', true).orderBy('popularity', 'desc').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                .catch(err => {
                    console.log(err);
                });
                
                commit('setDishes', data);
            }
        },
        async getDish({commit, dispatch}, id){
            if(!this.state.dishes.length){
                let data;
                await db.collection('/evolbe-modules/restaurant/dishes').doc(id).get().then(res => {
                    data = res.data();
                })
                .catch(err => {
                    console.log(err);
                });
                commit('setDish', data);
                return data;
            } else {
                const data = this.state.dishes.find(x => x.objectID === id);
                commit('setDish', data);
                return data;
            }
        },

        // Dishes Categories
        async getDishesCategories({commit, dispatch}){
            if(!this.state.dishesCategories.length){

                const data = [];
                await db.collection('/evolbe-modules/restaurant/categories').where('active', '==', true).orderBy('order', 'asc').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                .catch(err => {
                    console.log(err);
                });
                
                commit('setDishesCategories', data);
            }
        },

        // Promotions
        async getPromotions({commit, dispatch}){
            if(!this.state.promotions.length){
                const data = [];
                await db.collection('/evolbe-modules/restaurant/promotions').where('active', '==', true).orderBy('order', 'asc').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                .catch(err => {
                    console.log(err);
                });
                
                commit('setPromotions', data);
            }
        },
        async getPromotion({commit, dispatch}, id){
            if(!this.state.promotions.length){
                let data;
                await db.collection('/evolbe-modules/restaurant/promotions').doc(id).get().then(res => {
                    data = res.data();
                })
                .catch(err => {
                    console.log(err);
                });
                commit('setPromotion', data);
                return data;

            } else {
                const data = this.state.promotions.find(x => x.objectID === id);
                commit('setPromotion', data);
                return data;
            }
        },

        // Todays Menu
        async getTodaysMenu({commit, dispatch}){
            const hora = new Date().getHours();

            if(hora < 16){
                if(!this.state.todaysMenu){
                    const today = format(new Date(), 'MM/dd/yyyy');
    
                    const data = [];
                    await db.collection('/evolbe-modules/restaurant/todays-menu').where('date', '==', today).limit(1).get().then(res => {
                        res.forEach(item => {
                            const e = {
                            objectID: item.id,
                            ...item.data()
                            }
                            data.push(e);
                        });
                    }).catch(err => {
                        console.log(err);
                    });
    
                    if(data.length){
                        commit('setTodaysMenu', data[0]);
                        return data[0];
                    }
                } else {
                    return this.state.todaysMenu;
                }
            }
        },

        // Rewards
        async getRewards({commit, dispatch}){
            if(!this.state.rewards.length){

                const data = [];
                await db.collection('/evolbe-modules/restaurant/rewards').where('active', '==', true).orderBy('points', 'asc').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                .catch(err => {
                    console.log(err);
                });
                
                commit('setRewards', data);
            }
        },
        async getReward({commit, dispatch}, id){
            if(!this.state.rewards.length){
                let data;
                await db.collection('/evolbe-modules/restaurant/rewards').doc(id).get().then(res => {
                    data = res.data();
                })
                .catch(err => {
                    console.log(err);
                });
                commit('setReward', data);
                return data;
            } else {
                const data = this.state.rewards.find(x => x.objectID === id);
                commit('setReward', data);
                return data;
            }
        },

    // COUPONS
    async getCoupons({commit, dispatch}){
        if(!this.state.coupons){

            const data = [];
            await db.collection('/evolbe-modules/coupons/public').where('active', '==', true).orderBy('dateCreated', 'asc').get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })
            })
            .catch(err => {
                console.log(err);
            });
            commit('setCoupons', data);
        }
    },
    async getPrivateCoupons({commit, dispatch}){
        if(this.state.user){

            const data = [];
            await db.collection('/evolbe-modules/coupons/private').where('active', '==', true).where('customer.uid', '==', this.state.user.uid).orderBy('dateCreated', 'asc').get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })
            })
            .catch(err => {
                console.log(err);
            });
            
            commit('setPrivateCoupons', data);
        }
    },

    async subUsesCoupon({commit, dispatch}, {coupon, type}){
        if(type === 'public'){
            // Actualizar BD
            const newCoupon = JSON.parse(JSON.stringify(coupon));
            newCoupon.uses = newCoupon.uses - 1;

            if(newCoupon.uses <= 0){
                await db.collection('/evolbe-modules/coupons/public').doc(coupon.objectID).delete();

                if(this.state.coupons && this.state.coupons.length){
                    const index = this.state.coupons.findIndex(x => x.objectID === coupon.objectID );
                    const newCoupons = JSON.parse(JSON.stringify(this.state.coupons));
                    newCoupons.splice(index,1);
                    commit('setCoupons', newCoupons);
                }
            } else {
                await db.collection('/evolbe-modules/coupons/public').doc(coupon.objectID).update(newCoupon);

                // Actualizar Local
                if(this.state.coupons && this.state.coupons.length){
                    const index = this.state.coupons.findIndex(x => x.objectID === coupon.objectID );
                    const newCoupons = JSON.parse(JSON.stringify(this.state.coupons));
                    newCoupons[index] = newCoupon;
                    commit('setCoupons', newCoupons);
                }
            }
            
        } else if(type === 'private'){
            // Actualizar BD
            const newCoupon = JSON.parse(JSON.stringify(coupon));
            newCoupon.uses = newCoupon.uses - 1;

            if(newCoupon.uses <= 0){
                await db.collection('/evolbe-modules/coupons/private').doc(coupon.objectID).delete();

                // Actualizar Local
                if(this.state.privateCoupons && this.state.privateCoupons.length){
                    const index = this.state.privateCoupons.findIndex(x => x.objectID === coupon.objectID );
                    const newCoupons = JSON.parse(JSON.stringify(this.state.privateCoupons));
                    newCoupons.splice(index,1);
                    commit('setPrivateCoupons', newCoupons);
                }
            } else {
                await db.collection('/evolbe-modules/coupons/private').doc(coupon.objectID).update(newCoupon);
                
                // Actualizar Local
                if(this.state.privateCoupons && this.state.privateCoupons.length){
                    const index = this.state.privateCoupons.findIndex(x => x.objectID === coupon.objectID );
                    const newCoupons = JSON.parse(JSON.stringify(this.state.privateCoupons));
                    newCoupons[index] = newCoupon;
                    commit('setPrivateCoupons', newCoupons);
                }
            }

        } else {
            //Error
            console.log('Error al descontar lso usos del cupón');
        }
    },


    // DESAYUNOS
    async getDesayunos({commit, dispatch}){
        if(!this.state.desayunos.length){

            const data = [];
            await db.collection('/evolbe-modules/experiencias/experiencias').where('active', '==', true).orderBy('order', 'asc').get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })
            })
            .catch(err => {
                console.log(err);
            });
            
            commit('setDesayunos', data);
        }
    },


    // USER
        // Login & Register
        async loginPhone({commit, dispatch}, usuario){
            const loading = await loadingController.create({
                message: 'Iniciando sesión...',
                duration: 5000,
                mode: 'ios',
                spinner: 'circular',
                cssClass: 'loader'
              });
            loading.present();
            
            try {
                const data = [];
                await db.collection('/users').where('phone', '==', usuario.phone).limit(1).get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                
                const user = data[0];

                if(user){
                    if(user.provider === 'phone'){
                        if(!user.phoneEmail){
                            user.phoneEmail = usuario.countryCode + '.' + usuario.phone + '@docemasunorestaurante.com';
                        }
        
                        const res = await auth.signInWithEmailAndPassword(user.phoneEmail, usuario.password);
                        await db.collection('/users').doc(res.user.uid).get().then(async user => {
                            if(user.exists){
                                const myUser = user.data();
                                myUser.uid = res.user.uid;
                                
                                myUser.lastLogin = new Date().getTime();
                                myUser.version = 532;
                                await db.collection('users').doc(myUser.uid).update(myUser);
        
                                commit('setUser', myUser);
                                localStorage.setItem('user', JSON.stringify(myUser));
                                dispatch('loadCart');                        
        
                                loading.dismiss();
                                GA4_login(myUser);

                                if(!myUser.verify){
                                    router.push('/verify-phone');
                                } else {
                                    router.push(this.state.returnURL);
                                }
                            }
                        });
                    } else {
                        console.log('Bad provider');
                        loading.dismiss();
                        dispatch('openToast', {message: 'Tu cuenta se creo usando ' + user.provider + '. Por favor utiliza este método para iniciar sesión.', color: 'danger'});
                    }
                } else {
                    console.log('Bad user');
                    loading.dismiss();
                    dispatch('openToast', {message: 'Ups, parece que el usuario no existe', color: 'danger'});
                }                

            } catch (error) {
                // ACTUALIZAR
                // Cambiar Console Log por Console error
                console.log(error);
                loading.dismiss();

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                console.log(error.code);

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                switch (error.code) {
                    case 'auth/user-not-found':
                        dispatch('openToast', {message: 'Ups, parece que el usuario no existe', color: 'danger'});
                    break;

                    case 'auth/wrong-password':
                        dispatch('openToast', {message: 'Ups, parece que la contraseña no es correcta', color: 'danger'});
                    break;

                    case 'auth/network-request-failed':
                        dispatch('openToast', {message: 'Lo sentimos no pudimos comunicarnos con el servidor, por favor verifica tu conexión he inténtalo de nuevo', color: 'danger'});
                    break;
                
                    default:
                        dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                    break;
                }            

                return error
            }
        },

        loginOnce({commit, dispatch}, uid){
            db.collection('users').doc(uid).get().then(res => {
                const myUser = res.data()
                commit('setUser', myUser);
                return myUser;
            });
        },

        async loginFacebook({commit, dispatch}){
            const loading = await loadingController.create({
                message: 'Iniciando sesión...',
                duration: 5000,
                mode: 'ios',
                spinner: 'circular',
                cssClass: 'loader'
              });
            loading.present();

            try{
                const result = await FirebaseAuthentication.signInWithFacebook();
                
                if(result.user){
                    const myUser = {
                        email: '',
                        provider: 'facebook.com',
                        firstName: result.user.displayName,
                        lastName: '',
                        phone: result.user.phoneNumber || null,
                        uid: result.user.uid,
                        dateCreated: new Date().getTime(),
                        lastLogin: new Date().getTime(),
                        verifyCode: Math.floor(100000 + Math.random() * 900000),
                        verify: false, 
                        points: 0,
                        version: 532,
                        achievements: {}
                    }

                    const resp = await db.collection('users').doc(result.user.uid).get();
                    const exists = resp.data();
                    if(exists){
                        // Si existe el usuario -> Login
                        const myUser = JSON.parse(JSON.stringify(exists));
                        myUser.lastLogin = new Date().getTime();
                        myUser.version = 532;
                        await db.collection('users').doc(myUser.uid).update(myUser);

                        commit('setUser', myUser);
                        localStorage.setItem('user', JSON.stringify(myUser));
                        dispatch('loadCart');

                        GA4_login(myUser);
                        FB_login(myUser);
    
                        if(!myUser.verify){
                            router.push('/verify-phone');
                        } else {
                            router.push(this.state.returnURL);
                        }
                    } else {
                        // Si no existe el usuario -> Se necesita teléfono
                        commit('setUser', myUser);
                        localStorage.setItem('user', JSON.stringify(myUser));
                        router.push('/add-phone');
                    }

                    loading.dismiss();
                }
            } catch (error) {
                // ACTUALIZAR
                // Cambiar Console Log por Console error
                console.log(error);
                loading.dismiss();

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                switch (error.code) {
                    case 'auth/popup-closed-by-user':
                        dispatch('openToast', {message: 'Lo sentimos, se cerro la ventana antes de completar el proceso', color: 'danger'});
                    break;

                    case 'auth/popup-blocked':
                        dispatch('openToast', {message: 'Lo sentimos, su navegador ha bloqueado la ventana emergente de para el inicio de sesión', color: 'danger'});
                    break;

                    case 'auth/wrong-password':
                        dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                    break;

                    case 'auth/no-such-provider':
                        dispatch('openToast', {message: 'Lo sentimos, falló la conexión con el proveedor de inició de sesión', color: 'danger'});
                    break;

                    case 'auth/network-request-failed':
                        dispatch('openToast', {message: 'Lo sentimos no pudimos comunicarnos con el servidor, por favor verifica tu conexión he inténtalo de nuevo', color: 'danger'});
                    break;
                
                    default:
                        dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                    break;
                }            

                return error
            }
        },

        async loginGoogle({commit, dispatch}){
            const loading = await loadingController.create({
                message: 'Iniciando sesión...',
                duration: 5000,
                mode: 'ios',
                spinner: 'circular',
                cssClass: 'loader'
              });
            loading.present();

            try{
                const result = await FirebaseAuthentication.signInWithGoogle();

                if(result.user){
                    const myUser = {
                        email: result.user.email,
                        provider: 'google.com',
                        firstName: result.user.displayName,
                        lastName: '',
                        phone: result.user.phoneNumber || null,
                        uid: result.user.uid,
                        dateCreated: new Date().getTime(),
                        lastLogin: new Date().getTime(),
                        verifyCode: Math.floor(100000 + Math.random() * 900000),
                        verify: false,
                        points: 0,
                        version: 532,
                        achievements: {}
                    }

                    const resp = await db.collection('users').doc(result.user.uid).get();
                    const exists = resp.data()
                    if(exists){
                        // Si existe el usuario -> Login
                        const myUser = JSON.parse(JSON.stringify(exists));
                        myUser.lastLogin = new Date().getTime();
                        myUser.version = 532;
                        await db.collection('users').doc(myUser.uid).update(myUser);
                        
                        commit('setUser', myUser);
                        localStorage.setItem('user', JSON.stringify(myUser));
                        dispatch('loadCart');

                        GA4_login(myUser);
                        FB_login(myUser);
    
                        if(!myUser.verify){
                            router.push('/verify-phone');
                        } else {
                            router.push(this.state.returnURL);
                        }
                    } else {
                        // Si no existe el usuario -> Se necesita teléfono
                        commit('setUser', myUser);
                        localStorage.setItem('user', JSON.stringify(myUser));
                        router.push('/add-phone');
                    }

                    loading.dismiss();
                }
            } catch (error) {
                // ACTUALIZAR
                // Cambiar Console Log por Console error
                console.log(error);
                loading.dismiss();

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                switch (error.code) {
                    case 'auth/popup-closed-by-user':
                        dispatch('openToast', {message: 'Lo sentimos, se cerro la ventana antes de completar el proceso', color: 'danger'});
                    break;

                    case 'auth/popup-blocked':
                        dispatch('openToast', {message: 'Lo sentimos, su navegador ha bloqueado la ventana emergente de para el inicio de sesión', color: 'danger'});
                    break;

                    case 'auth/wrong-password':
                        dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                    break;

                    case 'auth/no-such-provider':
                        dispatch('openToast', {message: 'Lo sentimos, falló la conexión con el proveedor de inició de sesión', color: 'danger'});
                    break;

                    case 'auth/network-request-failed':
                        dispatch('openToast', {message: 'Lo sentimos no pudimos comunicarnos con el servidor, por favor verifica tu conexión he inténtalo de nuevo', color: 'danger'});
                    break;
                
                    default:
                        dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                    break;
                }            

                return error
            }
        },

        async loginApple({commit, dispatch}){
            const loading = await loadingController.create({
                message: 'Iniciando sesión...',
                duration: 5000,
                mode: 'ios',
                spinner: 'circular',
                cssClass: 'loader'
              });
            loading.present();

            try{
                const result = await FirebaseAuthentication.signInWithApple();

                if(result.user){
                    const myUser = {
                        email: result.user.email,
                        provider: 'apple.com',
                        firstName: result.user.displayName,
                        lastName: '',
                        phone: result.user.phoneNumber || null,
                        uid: result.user.uid,
                        dateCreated: new Date().getTime(),
                        lastLogin: new Date().getTime(),
                        verifyCode: Math.floor(100000 + Math.random() * 900000),
                        verify: false,
                        points: 0,
                        version: 532,
                        achievements: {}
                    }

                    const resp = await db.collection('users').doc(result.user.uid).get();
                    const exists = resp.data()
                    if(exists){
                        // Si existe el usuario -> Login
                        const myUser = JSON.parse(JSON.stringify(exists));
                        myUser.lastLogin = new Date().getTime();
                        myUser.version = 532;
                        await db.collection('users').doc(myUser.uid).update(myUser);
                        
                        commit('setUser', myUser);
                        localStorage.setItem('user', JSON.stringify(myUser));
                        dispatch('loadCart');

                        GA4_login(myUser);
                        FB_login(myUser);
    
                        if(!myUser.verify){
                            router.push('/verify-phone');
                        } else {
                            router.push(this.state.returnURL);
                        }
                    } else {
                        // Si no existe el usuario -> Se necesita teléfono
                        commit('setUser', myUser);
                        localStorage.setItem('user', JSON.stringify(myUser));
                        router.push('/add-phone');
                    }

                    loading.dismiss();
                }
            } catch (error) {
                // ACTUALIZAR
                // Cambiar Console Log por Console error
                console.log(error);
                loading.dismiss();

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                switch (error.code) {
                    case 'auth/popup-closed-by-user':
                        dispatch('openToast', {message: 'Lo sentimos, se cerro la ventana antes de completar el proceso', color: 'danger'});
                    break;

                    case 'auth/popup-blocked':
                        dispatch('openToast', {message: 'Lo sentimos, su navegador ha bloqueado la ventana emergente de para el inicio de sesión', color: 'danger'});
                    break;

                    case 'auth/wrong-password':
                        dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                    break;

                    case 'auth/no-such-provider':
                        dispatch('openToast', {message: 'Lo sentimos, falló la conexión con el proveedor de inició de sesión', color: 'danger'});
                    break;

                    case 'auth/network-request-failed':
                        dispatch('openToast', {message: 'Lo sentimos no pudimos comunicarnos con el servidor, por favor verifica tu conexión he inténtalo de nuevo', color: 'danger'});
                    break;
                
                    default:
                        dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                    break;
                }            

                return error
            }
        },

        async registerPhone({commit, dispatch}, usuario){
            const loading = await loadingController.create({
                message: 'Creando cuenta...',
                duration: 5000,
                mode: 'ios',
                spinner: 'circular',
                cssClass: 'loader'
              });
            loading.present();

            try {
                usuario.phoneEmail = usuario.countryCode + '.' + usuario.phone + '@docemasunorestaurante.com';
                const res = await auth.createUserWithEmailAndPassword(usuario.phoneEmail, usuario.password);

                delete usuario.password;
                usuario.verifyCode = Math.floor(100000 + Math.random() * 900000);
                usuario.verify = false;
                usuario.dateCreated = new Date().getTime();
                usuario.lastLogin = new Date().getTime();
                usuario.provider = 'phone';
                usuario.points = 0;
                usuario.version = 532;
                usuario.achievements = {};
                usuario.uid = res.user.uid;

                await db.collection('users').doc(res.user.uid).set(usuario);
                const myUser = JSON.parse(JSON.stringify(usuario));
                myUser.uid = res.user.uid;
                
                commit('setUser', myUser);
                localStorage.setItem('user', JSON.stringify(myUser));
                dispatch('loadCart');

                loading.dismiss();
                GA4_sign_up(myUser);
                FB_sign_up(myUser);

                router.push('/verify-phone');
            } catch (error) {
                // ACTUALIZAR
                // Cambiar Console Log por Console error
                console.log(error);
                loading.dismiss();

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                switch (error.code) {
                    case 'auth/email-already-in-use':
                        dispatch('openToast', {message: 'Ya existe un usuario registrado con ese número de teléfono', color: 'danger'});
                    break;

                    case 'auth/network-request-failed':
                        dispatch('openToast', {message: 'Lo sentimos no pudimos comunicarnos con el servidor, por favor verifica tu conexión he inténtalo de nuevo', color: 'danger'});
                    break;
                
                    default:
                        dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                    break;
                }            

                return error
            }
        },

        async logout({commit, dispatch}){
            try {
                router.push('/');
                await auth.signOut();
                commit('setUser', undefined);
                localStorage.removeItem('user');
                dispatch('loadCart');
                return 0;
            } catch (error) {
                console.log(error);
                dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                return error;
            }
        },

        async refreshUser({commit, dispatch}){
            if(this.state.user){
                // Refresh user
                let data;
                await db.collection('/users').doc(this.state.user.uid).get().then(res => {
                    data = res.data()
                    data.uid = res.id;
                })
                .catch(err => {
                    console.log(err);
                });

                if(data){
                    const newUser = JSON.parse(JSON.stringify(data));
                    newUser.lastLogin = new Date().getTime();
                    newUser.version = 532;
                    dispatch('updateUser', newUser);

                    if(!newUser.phone){
                        router.push('/add-phone');
                    }
                }
            }

        },

        async updateUser({commit, dispatch}, usuario){
            try {
                await db.collection('users').doc(usuario.uid).update(usuario);
                commit('setUser', usuario);
                localStorage.setItem('user', JSON.stringify(usuario));
            } catch (error) {
                console.log(error);
                dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                return error;
            }
        },

        async updatePhone({commit, dispatch}, phoneForm){
            try {
                const data = [];
                await db.collection('/users').where('phone', '==', phoneForm.phone).limit(1).get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                
                const user = data[0];

                if(user){
                    // Si existe el nº de telefono
                    const myUser = JSON.parse(JSON.stringify(user));
                    myUser.lastLogin = new Date().getTime();
                    myUser.version = 532;
                    await db.collection('users').doc(myUser.uid).update(myUser);
                    
                    commit('setUser', myUser);
                    localStorage.setItem('user', JSON.stringify(myUser));
                    dispatch('loadCart');
                    
                    GA4_login(myUser);
                    FB_login(myUser);

                    if(!myUser.verify){
                        router.push('/verify-phone');
                    } else {
                        router.push(this.state.returnURL);
                    }
                } else {
                    // Si no existe el nº de telefono 
                    const newUser = JSON.parse(JSON.stringify(this.state.user));
                    newUser.countryCode = phoneForm.countryCode;
                    newUser.phone = phoneForm.phone;

                    await db.collection('users').doc(newUser.uid).set(newUser);

                    commit('setUser', newUser);
                    localStorage.setItem('user', JSON.stringify(newUser));

                    GA4_sign_up(newUser);
                    FB_sign_up(newUser);

                    router.push('/verify-phone');
                }
            } catch (error) {
                console.log(error);
                dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                return error;
            }
        },

        async recoveryPassword({commit, dispatch}, recoverPassword){
            try {
                const data = [];
                await db.collection('/users').where('phone', '==', recoverPassword.phone).limit(1).get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                
                const user = data[0];

                if(user && (user.provider === 'phone' || user.provider === 'email')){
                    // Si el usuario existe
                    const phoneEmail = user.phoneEmail || user.email;

                    fetch('https://us-central1-docemasunorestaurante-com.cloudfunctions.net/sendRecoveryPasswordSMS', { method: 'POST', body: JSON.stringify({
                        "phoneEmail": phoneEmail,
                        "to": "+" + recoverPassword.countryCode + recoverPassword.phone,
                    })
                    }).then(() => {
                        dispatch('openToast', {message: 'Te hemos enviado un SMS con un enlace para reestablecer tu contraseña', color: 'success'});
                        router.push({path: '/login'});
                    });
                } else if(user && (user.provider !== 'phone' || user.provider !== 'email')) {
                    // Si el proveedor de usuario es otro
                    dispatch('openToast', {message: 'Tu usuario está registrado con ' + user.provider + '. Por favor inicia sesión usando este servicio', color: 'danger'});
                    router.push({path: '/login'});
                } else {
                    // Si el usuario no existe
                    dispatch('openToast', {message: 'Oops, parece que no existe ningún usuario con este número de teléfono. Por favor asegurate de que es correcto', color: 'danger'});
                }
              
            } catch (error) {
                console.log(error);
                dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                return error;
            }
        },

        async deleteUser({commit, dispatch}, {email, password, uid}){
            try {
                await auth.signInWithEmailAndPassword(email, password);
                auth.currentUser.delete();

                await db.collection('users').doc(uid).delete();

                commit('setUser', null);
                localStorage.removeItem('user');
            } catch (error) {
                console.log(error);
                dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal 😢', color: 'danger'});
                return error;
            }
        },

        // Orders
        async getUserOrders({commit, dispatch}, phone){
                const data = [];
                await db.collection('/evolbe-modules/restaurant/orders').where('customer.phone', '==', phone).orderBy('datePurchased', 'desc').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })

                    commit('setUserOrders', data);
                })
        },

        async getUserOrder({ commit, dispatch }, id) {
            console.log(id);
            try {
              const orderDoc = await db.collection('/evolbe-modules/restaurant/orders').doc(id).get();
              const orderData = orderDoc.data();
              return orderData;
            } catch (error) {
              // Manejar el error aquí si es necesario
              console.error(error);
              return null; // Otra acción en caso de error, como lanzar una excepción
            }
          },

        // Points
        async getUserPoints({commit, dispatch}, uid){
            const data = [];
            await db.collection(`/users/${uid}/points-history`).orderBy('dateCreated', 'desc').get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })

                commit('setUserPoints', data);
            })
        },

        async addPointsRecord({commit, dispatch}, {uid, record}){
            try {
                await db.collection(`/users/${uid}/points-history`).add(record);
            } catch (error) {
                console.log(error);
                dispatch('openToast', {message: 'Ups, lo sentimos parece que algo salio mal al registrar tus puntos 😢', color: 'danger'});
                return error;
            }
        },



    // CART
        addCart({commit, dispatch}, product){
            const data = this.state.cart;

            if(data){
                data.items.push(product);

                // Recalcular
                data.total = 0;
                data.subtotal = 0;
                data.tax = 0;

                // Productos
                if(data.items && data.items.length){
                    data.items.forEach(product => {
                        if(product.price && product.quantity){
                            data.subtotal = data.subtotal + (product.price * product.quantity);
                        }
                    });
                }

                data.total = (data.subtotal + data.shipping + data.surcharge) - data.discount;
                data.tax = (data.subtotal * 10) / 100;

                dispatch('updateCart', data);

                if(product.type === 'PROMO'){
                    GA4_add_to_cart_promotion(product);
                    FB_add_to_cart_promotion(this.state.user, product);
                } else if(product.type === 'MENU'){
                    GA4_add_to_cart_menu(product);
                    FB_add_to_cart_menu(this.state.user, product);
                } else if(product.type === 'REWARD'){
                    GA4_add_to_cart_reward(product);
                    FB_add_to_cart_reward(this.state.user, product);
                } else {
                    GA4_add_to_cart_item(product);
                    FB_add_to_cart_item(this.state.user, product);
                }

            } else {
                dispatch('loadCart');
            }
        },

        async updateCart({commit, dispatch}, cart){
            if(cart){
                if(this.state.user){
                    if(!cart.customer){
                        cart.customer = this.state.user;
                    }
    
                    try {
                        // Actualizar Fecha
                        cart.dateUpdated = new Date().getTime();
                        await db.collection('/evolbe-modules/restaurant/orders').doc(cart.objectID).update(cart);
                    } catch (error) {
                        await db.collection('/evolbe-modules/restaurant/orders').add(cart).then((result) => {
                            cart.objectID = result.id;
                            console.log('crear carrito');
                        })
                    }

                    commit('setCart', cart);
                    return cart;
                } else {
                    localStorage.setItem('cart', JSON.stringify(cart));
                }
            } else {
                dispatch('loadCart');
            }
        },

        async resetCart({commit, dispatch}){
            commit('setTrackingOrder', this.state.cart);
            localStorage.setItem('trackingOrder', JSON.stringify(this.state.cart));
            dispatch('getTrackingOrder');

            commit('setCart', undefined);
            dispatch('loadCart');

            return this.state.cart;
        },
        
        async loadCart({commit, dispatch}){
            if(this.state.cart === undefined) {
                if(router.currentRoute.value.query.recover){
                    // Si es una ruta de recuperación
                    // Eliminar carrito actual 
                    if(this.state.cart){
                        await db.collection('/evolbe-modules/restaurant/orders').doc(this.state.cart.objectID).delete();
                    }

                    let data;
                    await db.collection('/evolbe-modules/restaurant/orders').doc(router.currentRoute.value.query.recover.toString()).onSnapshot(res => {
                        data = res.data();
                    });

                    commit('setCart', data);
                } else {
                    // Si nay una carrito del usuario recuperlo
                    if(this.state.user && this.state.user.phone){
                        await db.collection('/evolbe-modules/restaurant/orders').where('customer.phone', "==", this.state.user.phone).where('status', '==', 0).orderBy('dateUpdated', 'desc').limit(1).get().then(async res => {
                            const data = [];
                            res.forEach(item => {
                                const e = {
                                objectID: item.id,
                                ...item.data()
                                }
                                data.push(e);
                            });
                        
                            if(data.length){
                                // Hay Carrito del usuario
                                const order = data[0];
                                commit('setCart', order);
                            }  else {
                                // Si no hay carrito del usuario, crear un carrito nuevo
                                const order = {
                                    dateCreated: new Date().getTime(),
                                    dateUpdated: new Date().getTime(),
                                    customer: this.state.user,
                                    status: 0,
                                    total: 0,
                                    subtotal: 0,
                                    tax: 0,
                                    shipping: 0,
                                    surcharge: 0,
                                    discount: 0,
                                    items: [],
                                    objectID: undefined,
                                    delivery: true,
                                    payment: {},
                                    platform: 'PWA'
                                }
                    
                                await db.collection('/evolbe-modules/restaurant/orders').add(order).then((result) => {
                                    order.objectID = result.id;
                                    commit('setCart', order);
                                }).catch((err) => {
                                    console.log(err);
                                });
                            }

                        }); 


                    } else {
                        // ACTUALIZAR (Guardar carritos sin usuario en local)
                        if(localStorage.getItem('cart') !== undefined && localStorage.getItem('cart') !== null){
                            const order = JSON.parse(localStorage.getItem('cart'));
                            commit('setCart', order);
                        } else {
                            console.log('añadir carrito temporal');
                            const order = {
                                dateCreated: new Date().getTime(),
                                dateUpdated: new Date().getTime(),
                                customer: undefined,
                                status: 0,
                                total: 0,
                                subtotal: 0,
                                tax: 0,
                                shipping: 0,
                                surcharge: 0,
                                discount: 0,
                                items: [],
                                objectID: undefined,
                                delivery: true,
                                payment: {},
                                platform: 'PWA'
                            }

                            commit('setCart', order);
                        }
                    }
                }
            }
            return 0;
        },

        /*async getTrackingOrder({commit, dispatch}){
            if(this.state.trackingOrder === undefined){
                if(this.state.user){
                    await db.collection('/evolbe-modules/restaurant/orders').where('customer.phone', '==', this.state.user.phone).where('status', '>', 0).where('status', '<', 4).orderBy('status', 'asc').orderBy('datePurchased', 'desc').limit(1).onSnapshot(res => {
                        const data = [];
                        res.forEach(item => {
                            const e = {
                            objectID: item.id,
                            ...item.data()
                            }
                            data.push(e);
                        })

                        if(data.length){
                            console.log('entrada 1', data);
                            commit('setTrackingOrder', data[0]);
                        }
                    })
                }
            } else {
                if(this.state.trackingOrder.status < 4 && this.state.trackingOrder.status > 0){
                    await db.collection('/evolbe-modules/restaurant/orders').doc(this.state.trackingOrder.objectID).onSnapshot(res => {
                        const newData = res.data();
                        newData.objectID = res.id;
                        
                        commit('setTrackingOrder', newData);
                        localStorage.setItem('trackingOrder', JSON.stringify(newData));
                        console.log('entrada 2');
                    })
                } else{
                    commit('setTrackingOrder', undefined);
                    localStorage.removeItem('trackingOrder');
                }
            }
        },*/

        async getTrackingOrder({commit, dispatch}, id){
            if(this.state.trackingOrder === undefined){
                if(id){
                    return new Promise((resolve, reject) => {
                        db.collection('/evolbe-modules/restaurant/orders').doc(id).onSnapshot(res => {
                            const data = res.data();
                    
                            commit('setTrackingOrder', data);
                            resolve(data);
                        })
                    });
                } else {
                    if(this.state.user){
                        await db.collection('/evolbe-modules/restaurant/orders').where('customer.phone', '==', this.state.user.phone).where('status', '>', 0).where('status', '<', 4).orderBy('status', 'asc').orderBy('datePurchased', 'desc').limit(1).get().then(res => {
                            const data = [];
                            res.forEach(item => {
                                const e = {
                                objectID: item.id,
                                ...item.data()
                                }
                                data.push(e);
                            })

                            if(data.length){
                                dispatch('getTrackingOrder', data[0].objectID);
                            }
                        })
                    } else {
                        return 0;
                    }
                }
            } else return 0;
        },


            

    // OTHERS
    offBigBanner({commit, dispatch}){
        commit('setBigBanner', false);
    },

    saveReturnURL({commit, dispatch}, url){
        commit('setReturnURL', url);
    },

    async openToast({commit, dispatch}, {message, color}) {
        let duration = 1000;
        if(color === 'danger'){
            duration = 2000;
        }

        const toast = await toastController
          .create({
            message,
            duration,
            cssClass: 'toast',
            mode: 'ios',
            color,
            position: 'bottom',
            keyboardClose: true,
            buttons: [
             {
                icon: closeOutline,
                role: 'cancel',
              }
            ]
          })
        return await toast.present();
    },

    loadLocalStorage({commit, dispatch}){
        // Set User
        if(this.state.user === undefined){
            if(localStorage.getItem('user')){
              const user = JSON.parse(localStorage.getItem('user'));
              commit('setUser', user);
              Sentry.setUser({ id: this.state.user.uid });

              return JSON.parse(localStorage.getItem('user'));
            } else {
              commit('setUser', null);
              return null;
            }
        } else {
            return this.state.user;
        }
    },

    // OneSignal
    loadNotifications({commit, dispatch}){
        if(this.state.user){
            document.addEventListener("deviceready", OneSignalInit, false);
        }
    },



    /* -------------------------------------------------- ACTUALIZACIONES ------------------------------------------------------ */

    async analiticasDB({commit, dispatch}){

    // PEDIDOS

        // Pedidos Mensuales
        /*
            const orders = [];
            const ordersByDay = {}; // Objeto para almacenar el número de pedidos por día
            let totalOrders = 0; // Variable para calcular el total de pedidos
        
            await db
            .collection('/evolbe-modules/restaurant/orders')
            .where('datePurchased', '>=', 1717113600000)
            .where('datePurchased', '<', 1717286400000)
            .where('status', '==', 4)
            .get()
            .then((res) => {
                res.forEach((item) => {
                const e = {
                    objectID: item.id,
                    ...item.data()
                };
                orders.push(e);
                });
            });
        
            const startDate = new Date(1717113600000);
            const endDate = new Date(1717286400000);
            
            const dateRange = eachDayOfInterval({ start: startDate, end: endDate });

            dateRange.forEach((date) => {
                const dayOfMonth = format(date, 'd');
            
                const ordersCount = orders.filter((order) => {
                const orderDate = new Date(order.datePurchased);
                return format(orderDate, 'd') === dayOfMonth && order.status === 4;
                }).length;
            
                ordersByDay[dayOfMonth] = ordersCount;
                totalOrders += ordersCount;
            });
            
            const averageOrders = totalOrders / Object.keys(ordersByDay).length;
            
            console.log('Pedidos por día:', ordersByDay);
            console.log('Media de pedidos:', averageOrders);
            console.log('Pedidos Totales', totalOrders);
        */




        // Distribución de Pedidos
        /*
            const orders = [];
            const ordersByDayOfWeekAndHour = {}; // Objeto para almacenar la distribución de pedidos por día de la semana y hora del día
        
            await db
            .collection('/evolbe-modules/restaurant/orders')
            .where('datePurchased', '>=', 1717113600000)
            .where('datePurchased', '<', 1688169600000)
            .get()
            .then((res) => {
                res.forEach((item) => {
                const e = {
                    objectID: item.id,
                    ...item.data()
                };
                orders.push(e);
                });
            });
        
            const hoursOfDay = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]; // Define las horas del día que deseas mostrar

            orders.forEach((order) => {
                if (order.status === 4) {
                const orderDate = new Date(order.datePurchased);
                const dayOfWeek = getDay(orderDate);
            
                if (!ordersByDayOfWeekAndHour[dayOfWeek]) {
                    ordersByDayOfWeekAndHour[dayOfWeek] = {};
                }
            
                hoursOfDay.forEach((hour) => {
                    if (!ordersByDayOfWeekAndHour[dayOfWeek][hour]) {
                    ordersByDayOfWeekAndHour[dayOfWeek][hour] = 0;
                    }
            
                    if (hour === getHours(orderDate)) {
                    ordersByDayOfWeekAndHour[dayOfWeek][hour]++;
                    }
                });
                }
            });
            
            console.log('Distribución de pedidos por día de la semana y hora del día:', ordersByDayOfWeekAndHour);
        */
    

        // TIPO DE PEDIDOS
        /*
            const pedidos = [];
            const tiposProductos = {
                PROMO: 0,
                MENU: 0,
                CARTA: 0
            };
            
            await db
                .collection('/evolbe-modules/restaurant/orders')
                .where('datePurchased', '>=', 1717113600000)
                .where('datePurchased', '<', 1717286400000)
                .get()
                .then((res) => {
                res.forEach((item) => {
                    const pedido = {
                    objectID: item.id,
                    ...item.data()
                    };
                    pedidos.push(pedido);
                });
                });
            
                const pedidosContados = new Set();

                pedidos.forEach((pedido) => {
                if(pedido.status === 4){
                    if (!pedidosContados.has(pedido.objectID)) {
                        const tiposUnicos = new Set();
                        const productos = pedido.items;
                        productos.forEach((producto) => { // Añade la anotación de tipo 'any' si no tienes un tipo específico para 'producto'
                          if (producto.type === 'PROMO') {
                            tiposUnicos.add('PROMO');
                          } else if (producto.type === 'MENU') {
                            tiposUnicos.add('MENU');
                          }
                        });
                  
                        if (tiposUnicos.size === 0) {
                          tiposProductos.CARTA++;
                        } else {
                          tiposUnicos.forEach((tipo) => { // Añade la anotación de tipo 'string' para 'tipo'
                            tiposProductos[tipo]++;
                          });
                        }
                  
                        pedidosContados.add(pedido.objectID);
                      }
                }

                });
                
            
            console.log('Tipos de Pedidos', tiposProductos);
        */

        // CUPONES
        /*
            const pedidos = [];
            const cuponesGastados: { [key: string]: number } = {};

            await db
            .collection('/evolbe-modules/restaurant/orders')
            .where('datePurchased', '>=', 1717113600000)
            .where('datePurchased', '<', 1680307200000)
            .get()
            .then((res) => {
            res.forEach((item) => {
                const pedido = {
                objectID: item.id,
                ...item.data()
                };
                pedidos.push(pedido);
            });
            });

            pedidos.forEach((pedido) => {
                if(pedido.status === 4){
                    const codigoCupon = pedido.coupon?.name || '';
            
                    if (codigoCupon) {
                      if (cuponesGastados[codigoCupon]) {
                        cuponesGastados[codigoCupon]++;
                      } else {
                        cuponesGastados[codigoCupon] = 1;
                      }
                    }
                }
            });

            console.log('Cupones', cuponesGastados);
        */

        // Plataforma
        /*
            const orders = [];

            // Obtener pedidos
            await db
            .collection('/evolbe-modules/restaurant/orders')
            .where('status', '==', 4)
            .where('datePurchased', '>=', 1675209600000)
            .where('datePurchased', '<', 1677628800000)
            .get()
            .then((res) => {
                res.forEach((item) => {
                const order = {
                    objectID: item.id,
                    ...item.data()
                };
                orders.push(order);
                });
            });
        
            // Objeto para almacenar los pedidos por plataforma
            const ordersByPlatform = {};
        
            // Agrupar pedidos por plataforma
            orders.forEach((order) => {
            const platform = order.platform;
        
            if (!ordersByPlatform[platform]) {
                ordersByPlatform[platform] = [];
            }
        
            ordersByPlatform[platform].push(order);
            });
        
            console.log('Pedidos por plataforma:', ordersByPlatform);
        */


        // Pedidos de media por usuario
            /*
                const orders = [];

                // Obtener pedidos
                await db
                .collection('/evolbe-modules/restaurant/orders')
                .where('status', '==', 4)
                .where('datePurchased', '>=', 1672531200000)
                .where('datePurchased', '<', 1696118400000)
                .get()
                .then((res) => {
                    res.forEach((item) => {
                    const order = {
                        objectID: item.id,
                        ...item.data()
                    };
                    orders.push(order);
                    });
                });
            
                // Objeto para almacenar los pedidos por customer.uid y su conteo
                const ordersByCustomer = {};
                let totalOrders = 0;
            
                // Agrupar y contar pedidos por customer.uid
                orders.forEach((order) => {
                const customerId = order.customer.uid;
            
                if (!ordersByCustomer[customerId]) {
                    ordersByCustomer[customerId] = 0;
                }
            
                ordersByCustomer[customerId]++;
                totalOrders++;
                });
            
                // Calcular la media de los pedidos por cliente
                const averageOrdersPerCustomer = totalOrders / Object.keys(ordersByCustomer).length;
            
                console.log('Pedidos por customer.uid:', ordersByCustomer);
                console.log('Media de pedidos por cliente:', averageOrdersPerCustomer);
            */

    // FACTURACION
        // Facturación Mensual
        /*
            const orders = [];
            const gananciasByDay = {}; // Objeto para almacenar las ganancias por día
            let totalOrders = 0; // Variable para calcular el total de pedidos
            let totalGanancias = 0; // Variable para calcular el total de ganancias
            let totalGananciasMes = 0; // Variable para almacenar el total de ganancias del mes
            
            await db
            .collection('/evolbe-modules/restaurant/orders')
            .where('status', '==', 4)
            .where('datePurchased', '>=', 1717113600000)
            .where('datePurchased', '<', 1717286400000)
            .get()
            .then((res) => {
                res.forEach((item) => {
                const e = {
                    objectID: item.id,
                    ...item.data()
                };
                orders.push(e);
                });
            });
        
            const startDate = new Date(1717113600000);
            const endDate = new Date(1717286400000);
            const dateRange = eachDayOfInterval({ start: startDate, end: endDate });
        
            dateRange.forEach((date) => {
            const dayOfMonth = format(date, 'd');
            
            const ordersOfDay = orders.filter((order) => {
                const orderDate = new Date(order.datePurchased);
                return format(orderDate, 'd') === dayOfMonth && order.status === 4;
            });
            
            const ordersCount = ordersOfDay.length;
            const totalGananciasOfDay = ordersOfDay.reduce((acc, order) => acc + order.total, 0);
            
            gananciasByDay[dayOfMonth] = Math.round(totalGananciasOfDay);
            
            totalOrders += ordersCount;
            totalGanancias += totalGananciasOfDay;
            totalGananciasMes += totalGananciasOfDay;
            });
            
            const averageOrders = totalOrders / Object.keys(gananciasByDay).length;
            const averageGananciasPerOrder = Math.round(totalGanancias / totalOrders);
            const averageGananciasPerDay = Math.round(totalGanancias / Object.keys(gananciasByDay).length);
            
            console.log('Ganancias por día:', gananciasByDay);
            console.log('Media de pedidos:', averageOrders);
            console.log('Media de ganancias por pedido:', averageGananciasPerOrder);
            console.log('Media de ganancias por día:', averageGananciasPerDay);
            console.log('Total de ganancias del mes:', totalGananciasMes);
        */
        

            // TIPO DE PAGO
            /*
                const orders = [];
                const pedidosPorTipoPago = {}; // Objeto para almacenar los pedidos por tipo de pago
                
                await db
                  .collection('/evolbe-modules/restaurant/orders')
                  .where('datePurchased', '>=', 1672531200000)
                  .where('datePurchased', '<', 1688169600000)
                  .where('status', '==', 4)
                  .get()
                  .then((res) => {
                    res.forEach((item) => {
                      const e = {
                        objectID: item.id,
                        ...item.data()
                      };
                      orders.push(e);
                    });
                  });
                
                orders.forEach((order) => {
                if (order.status === 4) {
                    const tipoPago = order.payment.method;
                    
                    if (pedidosPorTipoPago[tipoPago]) {
                    pedidosPorTipoPago[tipoPago]++;
                    } else {
                    pedidosPorTipoPago[tipoPago] = 1;
                    }
                }
                });
                
                console.log('Pedidos por tipo de pago:', pedidosPorTipoPago);
            */

            // GASTOS DE ENVIO
            /*
                const orders = [];
                const pedidosPorGastosEnvio = {}; // Objeto para almacenar los pedidos por gastos de envío
    
                await db
                .collection('/evolbe-modules/restaurant/orders')
                .where('status', '==', 4)
                .where('datePurchased', '>=', 1672531200000)
                .where('datePurchased', '<', 1696118400000)
                .get()
                .then((res) => {
                    res.forEach((item) => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    };
                    orders.push(e);
                    });
                });
                
                orders.forEach((order) => {
                    if (order.status === 4) {
                      const gastosEnvio = order.shipping;
                      
                      if (pedidosPorGastosEnvio[gastosEnvio]) {
                        pedidosPorGastosEnvio[gastosEnvio]++;
                      } else {
                        pedidosPorGastosEnvio[gastosEnvio] = 1;
                      }
                    }
                });
                  
                console.log('Pedidos por gastos de envío:', pedidosPorGastosEnvio);
            */

        // USUARIOS
            // Usuarios Totales
            /*
                const users = [];
                let nuevosRegistros = 0;
            
                await db
                .collection('/users')
                .where('dateCreated', '<', 1693526400000)
                .get()
                .then((res) => {
                    res.forEach((item) => {
                    const user = {
                        objectID: item.id,
                        ...item.data()
                    };
                    users.push(user);
                    });
                });
            
                nuevosRegistros = users.length; 
            
                console.log('Número de usuarios:', nuevosRegistros);
            */

            // Usuarios Activos
            /*
                const allUsers = [];
                const userRes = await db.collection('/users').get();
                const batchSize = 100;  // Ajusta este valor según sea necesario
            
                for (let i = 0; i < userRes.docs.length; i += batchSize) {
                    const usersBatch = userRes.docs.slice(i, i + batchSize);
                    const promises = usersBatch.map(async (userDoc) => {
                        const user = {
                            objectID: userDoc.id,
                            ...userDoc.data()
                        };
                        const userOrdersQuery = await db
                            .collection('/evolbe-modules/restaurant/orders')
                            .where('customer.uid', '==', user.objectID)
                            .where('datePurchased', '>=', 1685577600000)
                            .where('datePurchased', '<', 1693526400000)
                            .where('status', '==', 4)
                            .get();
                        if (!userOrdersQuery.empty) {
                            allUsers.push(user);
                        }
                    });
                    await Promise.all(promises);
                    console.log('Batch: ', (i / 100), ' de ', (userRes.docs.length / 100));
                }
            
                console.log('Usuarios activos en los últimos 3 meses:', allUsers.length);
            */

            // Tipo de Registro
            /*¡
                const users = {};

                await db
                .collection('/users')
                .get()
                .then((userRes) => {
                    for (const userDoc of userRes.docs) {
                    const user = {
                        objectID: userDoc.id,
                        ...userDoc.data()
                    };

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const provider = user.provider || 'N/A'; // Si no se especifica un proveedor, se asigna "N/A"

                    if (!users[provider]) {
                        users[provider] = [];
                    }

                    users[provider].push(user);
                    }
                });

                console.log('Usuarios agrupados por proveedor:', users);
            */
    },


    // Productos

        async updateBD({commit, dispatch}){

            // DELETE USE COUPONS
            /*try {
                let lastVisible = null;
                const couponsToUpdate = [];
                const batchSize = 1000;
                let hasMoreOrders = true;

                console.log('Paso 1: Obtener cupones privados');
                // Paso 1: Obtener los cupones privados
                const privateCouponsSnapshot = await db.collection('/evolbe-modules/coupons/private').get();
                const privateCoupons = {};
                privateCouponsSnapshot.forEach(doc => {
                    privateCoupons[doc.id] = { id: doc.id, ...doc.data() };
                });
                console.log('Cupones privados obtenidos:', privateCoupons);

                console.log('Paso 2: Procesar orders en lotes de 1000');
                // Paso 2: Procesar orders en lotes de 1000
                while (hasMoreOrders) {
                    let query = db.collection('/evolbe-modules/restaurant/orders').limit(batchSize);
                    if (lastVisible) {
                        query = query.startAfter(lastVisible);
                    }

                    const ordersSnapshot = await query.get();
                    if (ordersSnapshot.empty) {
                        hasMoreOrders = false;
                        break;
                    }

                    const orders = [];
                    ordersSnapshot.forEach(doc => {
                        orders.push({ id: doc.id, ...doc.data() });
                    });

                    console.log('orders obtenidos:', orders);

                    console.log('Paso 3: Recorrer orders y actualizar cupones privados');
                    // Paso 3: Recorrer orders y actualizar cupones privados
                    orders.forEach(order => {
                        if (order.coupon && privateCoupons[order.coupon.objectID]) {
                            const coupon = privateCoupons[order.coupon.objectID];
                            coupon.uses -= 1;
                            console.log(`Cupón ${coupon.id} encontrado en el pedido ${order.id}. Usos restantes: ${coupon.uses}`);
                            if (coupon.uses <= 0) {
                                // Marcar el cupón para eliminarlo
                                console.log(`Cupón ${coupon.id} se eliminará porque sus usos son ${coupon.uses}`);
                                db.collection('/evolbe-modules/coupons/private').doc(coupon.id).delete();
                            } else {
                                // Marcar el cupón para actualizar sus usos
                                console.log(`Cupón ${coupon.id} se actualizará con usos: ${coupon.uses}`);
                                couponsToUpdate.push(coupon);
                            }
                        }
                    });

                    // Actualizar el último documento visible
                    lastVisible = ordersSnapshot.docs[ordersSnapshot.docs.length - 1];
                }

                console.log('Paso 4: Actualizar los cupones en Firestore');
                // Paso 4: Actualizar los cupones en Firestore
                const batch = db.batch();
                couponsToUpdate.forEach(coupon => {
                    const couponRef = db.collection('/evolbe-modules/coupons/private').doc(coupon.id);
                    batch.update(couponRef, { uses: coupon.uses });
                });
                await batch.commit();

                console.log('Cupones actualizados correctamente');
            } catch (error) {
                console.error('Error al actualizar los cupones:', error);
            }*/

            // Reducir tamaño pedidos
            /*const ordersRef = db.collection('evolbe-modules').doc('restaurant').collection('orders');
            const batchSize = 100; // Número máximo de pedidos por lote
            
            const processBatch = async (batch) => {
              const batchResult = await batch.commit();
              console.log('Lote de pedidos procesado');
              return batchResult;
            };
            
            const processOrders = async () => {
                const query = ordersRef.orderBy('dateCreated');
                let startAfter;
              
              const batchPromises: Promise<any>[] = [];
            
              do {
                let batchCount = 0;
                const batch = db.batch();
            
                let querySnapshot;
            
                if (startAfter) {
                  querySnapshot = await query.startAfter(startAfter).limit(batchSize).get();
                } else {
                  querySnapshot = await query.limit(batchSize).get();
                }
            
                querySnapshot.forEach((orderDoc) => {
                  const orderData = orderDoc.data();
                  orderData.dateUpdated = new Date().getTime();
                  const items = orderData.items || [];
            
                  for (const item of items) {
                    if (item.type === 'PROMO' && item.groups && item.groups.length) {
                      item.groups.forEach(group => {
                        const articles = group.articles || [];
                        const filteredArticles = articles.filter((article) => article.selected === true);
                        group.articles = filteredArticles;
                      });
                    }
                  }
            
                  const orderRef = ordersRef.doc(orderDoc.id);
                  batch.set(orderRef, orderData);
            
                  batchCount++;
                });
            
                if (batchCount > 0) {
                  batchPromises.push(processBatch(batch));
                }
            
                startAfter = querySnapshot.docs[querySnapshot.docs.length - 1];
              } while (startAfter);
            
              await Promise.all(batchPromises);
            
              console.log('Artículos no seleccionados eliminados de las promociones en los pedidos.');
            };
            
            await processOrders();*/

            // Obtener todos los pedidos que se hizieron en efectivo
            /*const ordersRef = db.collection('evolbe-modules').doc('restaurant').collection('orders');

            const querySnapshot = await ordersRef
                .where('datePurchased', '>=', 1687212000000)
                .where('datePurchased', '<=', 1687298400000)
                .get();

            let total = 0;

            console.log(querySnapshot);

            querySnapshot.forEach((orderDoc) => {
                const orderData = orderDoc.data();
                console.log(orderData);
                let orderTotal = 0;

                if(orderData.status === 4 && orderData.payment.method === 'dataphone'){
                   orderTotal = orderData.total;
                }


                total += orderTotal;
            });

            console.log(`El total de los pedidos en efectivo entre las fechas especificadas es: ${total}`);*/


            // Modificar Precios
            /*const dishesRef = db.collection('evolbe-modules').doc('restaurant').collection('dishes');

            const dishesSnapshot = await dishesRef.where('category.name', '==', 'Postres Caseros').get();

            const batch = db.batch();

            dishesSnapshot.forEach((dishDoc) => {
                const dishData = dishDoc.data();

                dishData.price = dishData.price - 1;

                console.log(dishData);
                const dishRef = dishesRef.doc(dishDoc.id);
                batch.set(dishRef, dishData);
            });

            await batch.commit();

            console.log('Precios actualizados en la categoría.');*/


            // Activar opción Sin Gluten en Platos
            /*const dishesRef = db.collection('evolbe-modules').doc('restaurant').collection('dishes');

            const dishesSnapshot = await dishesRef.where('category.name', '==', 'Hamburguesas').get();

            const batch = db.batch();

            dishesSnapshot.forEach((dishDoc) => {
            const dishData = dishDoc.data();

            const modifiers = dishData.modifiers || [];

            if (modifiers[1]) {
                const options = modifiers[1].options || [];

                const glutenOption = {
                name: 'Sin Gluten',
                price: 1,
                };

                modifiers[1].options = [...options, glutenOption];
            }

            const dishRef = dishesRef.doc(dishDoc.id);
            batch.set(dishRef, dishData);
            });

            await batch.commit();

            console.log('Opción "Sin Gluten" activada en los platos de la categoría "Hamburguesas".');*/



            // Activar opción Sin Gluten en Promociones
            /*const promotionsRef = db.collection('evolbe-modules').doc('restaurant').collection('promotions');

            const promotionsSnapshot = await promotionsRef.get();

            const batch = db.batch();

            promotionsSnapshot.forEach((promotionDoc) => {
            const promotionData = promotionDoc.data();

            const groups = promotionData.groups || [];

            groups.forEach((group) => {
                const articles = group.articles || [];

                articles.forEach((article) => {
                const category = article.category || {};

                if (category.name === 'Hamburguesas') {
                    const modifiers = article.modifiers || [];

                    if (modifiers[1]) {
                    const options = modifiers[1].options || [];

                    const glutenOption = {
                        name: 'Sin Gluten',
                        price: 1,
                    };

                    modifiers[1].options = [...options, glutenOption];
                    }
                }
                });
            });

            const promotionRef = promotionsRef.doc(promotionDoc.id);
            batch.set(promotionRef, promotionData);
            });

            await batch.commit();

            console.log('Opción "Sin Gluten" activada en los platos de las promociones de hamburguesas.');*/


            // Desactivar sin gluten Platos
            /*const dishesRef = db.collection('evolbe-modules').doc('restaurant').collection('dishes');

            const dishesSnapshot = await dishesRef.where('category.name', '==', 'Hamburguesas').get();
          
            const batch = db.batch();
            console.log(dishesSnapshot);
          
            dishesSnapshot.forEach((dishDoc) => {
              const dishData = dishDoc.data();
          
              const modifiers = dishData.modifiers || [];
          
              if (modifiers[1]) {
                const options = modifiers[1].options || [];
          
                const filteredOptions = options.filter((option) => option.name !== 'Pan Sin Gluten'); // o Sin Gluten
          
                modifiers[1].options = filteredOptions;
              }
          
              const dishRef = dishesRef.doc(dishDoc.id);
              console.log(dishData);
              batch.set(dishRef, dishData);
            });
          
            await batch.commit();
          
            console.log('Opción "Sin Gluten" eliminada de los platos de la categoría "hamburguesas".');*/


            // Desactivar sin gluten Promociones
                /*const promotionsRef = db.collection('evolbe-modules').doc('restaurant').collection('promotions');
              
                const promotionsSnapshot = await promotionsRef.get();
              
                const batch = db.batch();
              
                promotionsSnapshot.forEach((promotionDoc) => {
                  const promotionData = promotionDoc.data();
              
                  const groups = promotionData.groups || [];
              
                  groups.forEach((group) => {
                    const articles = group.articles || [];
              
                    articles.forEach((article) => {
                      const category = article.category || {};
              
                      if (category.name === 'Hamburguesas') {
                        const modifiers = article.modifiers || [];
              
                        if (modifiers[1]) {
                          const options = modifiers[1].options || [];
              
                          const filteredOptions = options.filter((option) => option.name !== 'Sin Gluten');
              
                          modifiers[1].options = filteredOptions;
                        }
                      }
                    });
                  });
              
                  const promotionRef = promotionsRef.doc(promotionDoc.id);
                  batch.set(promotionRef, promotionData);
                });
              
                await batch.commit();
              
                console.log('Opción "Sin Gluten" eliminada de los platos en las ofertas de hamburguesas.');*/
              

            // HISTORIAL DE PUNTOS
            /*const usersSnapshot = await db.collection('users').get();
            const batchSize = 100; // Número máximo de operaciones por lote
            const batches = [];
            
            // Dividir los usuarios en lotes más pequeños
            for (let i = 0; i < usersSnapshot.docs.length; i += batchSize) {
              console.log('batch', i);
              const batch = db.batch();
              const users = usersSnapshot.docs.slice(i, i + batchSize);
            
              for (const userDoc of users) {
                const userId = userDoc.id;
                console.log('usuario', userId);
                const achievements = userDoc.data().achievements;

                            
                let completeProfileCreated = false; // Bandera para controlar la creación del registro de completeProfile
                let phoneVerificationCreated = false; // Bandera para controlar la creación del registro de phoneVerification
            
                const pedidosSnapshot = await db
                  .collection('evolbe-modules')
                  .doc('restaurant')
                  .collection('orders')
                  .where('customer.phone', '==', userDoc.data().phone)
                  .where('datePurchased', '>=', 1663286400000)
                  .where('status', '==', 4)
                  .get();

                const pointsHistoryRef = db
                .collection('users')
                .doc(userId)
                .collection('points-history')
                .doc(); // Genera un nuevo ID de documento
            
                for (const pedidoDoc of pedidosSnapshot.docs) {
                  const order = pedidoDoc.data();
                  order.objectID = pedidoDoc.id;

                    let names = '';

                    order.items.forEach(item => {
                        names =  names + item.name + ', ';
                    });

                    names = names.substring(0, names.length - 2);

                  const register = {
                    name: names,
                    orderID: order.objectID,
                    add: true,
                    points: 0,
                    dateCreated: order.datePurchased,
                  };                  
            
                  const hayMenu = order.items.findIndex((x) => x.type === 'MENU');
                  const hayRegalo = order.items.findIndex((x) => x.type === 'REWARD');
                  const hayPromocion = order.items.findIndex((x) => x.type === 'PROMO');
            
                  if (hayMenu === -1 && hayRegalo === -1 && hayPromocion === -1) {
                    if (!order.coupon || !Object.keys(order.coupon).length) {
                      if (order.subtotal) {
                        register.points = Math.round(order.subtotal * 10);
                        console.log(register);
                        
                        batch.set(pointsHistoryRef, register);
                        console.log(`Pedido Creado: ${register}`);
                      }
                    }
                  }
                }
            
                if (achievements && achievements.completeProfile && !completeProfileCreated) {
                  const pointsHistoryRef = db
                    .collection('users')
                    .doc(userId)
                    .collection('points-history')
                    .doc();
                  const register2 = {
                    add: true,
                    points: 500,
                    name: 'Completa tu Perfil',
                    achievement: true,
                    dateCreated: userDoc.data().dateCreated,
                  };
            
                  batch.set(pointsHistoryRef, register2);
                  console.log(`Logro completeProfile Creado: ${register2}`);
                  completeProfileCreated = true;
                }
            
                if (achievements && achievements.phoneVerification && !phoneVerificationCreated) {
                  const pointsHistoryRef = db
                    .collection('users')
                    .doc(userId)
                    .collection('points-history')
                    .doc();
                  const register3 = {
                    add: true,
                    points: 100,
                    achievement: true,
                    name: 'Verifica tu número de teléfono',
                    dateCreated: userDoc.data().dateCreated,
                  };
            
                  batch.set(pointsHistoryRef, register3);
                  console.log(`Logro phoneVerification Creado: ${register3}`);
                  phoneVerificationCreated = true;
                }
              }
            
              batches.push(batch);
            }
            
            // Ejecutar los lotes de escritura
            for (const batch of batches) {
              await batch.commit();
            }
            
            console.log('Proceso completado');*/
          
        
        // RESTAR REGALOS

        /*const usersSnapshot = await db.collection('users').get();
            const batchSize = 100; // Número máximo de operaciones por lote
            const batches = [];
          
            // Dividir los usuarios en lotes más pequeños
            for (let i = 0; i < usersSnapshot.docs.length; i += batchSize) {
              console.log('batch', i);
              const batch = db.batch();
              const users = usersSnapshot.docs.slice(i, i + batchSize);
          
              for (const userDoc of users) {
                const userId = userDoc.id;
                console.log('usuario', userId);
          
                const pedidosSnapshot = await db
                  .collection('evolbe-modules')
                  .doc('restaurant')
                  .collection('orders')
                  .where('customer.uid', '==', userId)
                  .where('datePurchased', '>=', 1663286400000)
                  .where('status', '==', 4)
                  .get();
          
                for (const pedidoDoc of pedidosSnapshot.docs) {
                  const order = pedidoDoc.data();
          
                  for (const item of order.items) {
                    if (item.type === 'REWARD') {
                      const register = {
                        name: item.name,
                        orderID: order.objectID,
                        add: false,
                        points: item.points,
                        dateCreated: order.datePurchased,
                      };
          
                      const pointsHistoryRef = db
                        .collection('users')
                        .doc(userId)
                        .collection('points-history')
                        .doc();
          
                      batch.set(pointsHistoryRef, register);
                      console.log(`Registro de REWARD creado: ${register}`);
                    }
                  }
                }
              }
          
              batches.push(batch);
            }
          
            // Ejecutar los lotes de escritura
            for (const batch of batches) {
              await batch.commit();
            }
          
            console.log('Proceso completado');*/
          

        // CLEAN HISTORIAL DE PUNTOS
          
        /*const batchSize = 100; // Número máximo de operaciones por lote
          const usersRef = db.collection('users');


            try {
                const usersSnapshot = await usersRef.get();
                const batches = [];

                // Dividir los usuarios en lotes más pequeños
                for (let i = 0; i < usersSnapshot.docs.length; i += batchSize) {
                    console.log('batch', i);
                const batch = db.batch();
                const users = usersSnapshot.docs.slice(i, i + batchSize);

                for (const userDoc of users) {
                    
                    const userID = userDoc.id;
                    console.log('usuario', userID);
                    const pointsHistoryRef = userDoc.ref.collection('points-history');

                    const pointsHistorySnapshot = await pointsHistoryRef.get();

                    pointsHistorySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                    console.log(`Documento eliminado: ${doc.id}`);
                    });
                }

                batches.push(batch);
                }

                // Ejecutar los lotes de escritura
                for (const batch of batches) {
                await batch.commit();
                }

                console.log('Proceso completado');
            } catch (error) {
                console.error('Error al eliminar los documentos en "points-history":', error);
            }*/



            

            /* Devolver Puntos */
            /*const orders = [];
            await db.collection('/evolbe-modules/restaurant/orders').where('datePurchased', '>=', 1663286400000).get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    orders.push(e);
                })
            });

            const phoneOrdersMap = {};
            orders.forEach((order) => {
              const phone = order.customer.phone;
              if (phone) {
                if (!phoneOrdersMap[phone]) {
                  phoneOrdersMap[phone] = [];
                }
                phoneOrdersMap[phone].push(order);
              }
            });
          
            const phonePointsMap = {};
            Object.keys(phoneOrdersMap).forEach((phone) => {
              let points = 0;
              phoneOrdersMap[phone].forEach((order) => {
                    //Points
                    const hayMenu = order.items.findIndex(x => x.type === 'MENU');
                    const hayRegalo = order.items.findIndex(x => x.type === 'REWARD');
                    const hayPromocion = order.items.findIndex(x => x.type === 'PROMO');

                    if(hayMenu === -1 && hayRegalo === -1 && hayPromocion === -1){
                        if (!order.coupon || !Object.keys(order.coupon).length) {
                            points =  Math.round(order.subtotal * 10);
                        }
                    }
              });
              phonePointsMap[phone] = points;
            });

            const users = [];
            await db.collection('/users').get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    users.push(e);
                })
            });

            const updatedUsers = [];
            users.forEach((user) => {
              const phone = user.phone;             
              if (phone && phonePointsMap[phone]) {
                let newPoints = phonePointsMap[phone];

                if(user.achievements.phoneVerification){
                    newPoints = newPoints + 100;
                }

                if(user.achievements.completeProfile){
                    newPoints = newPoints + 500;
                }

                const updatedUser = {
                  ...user,
                  points: newPoints,
                };

                if(newPoints > user.points){
                    updatedUsers.push(updatedUser);
                }
              }
            });
            
            console.log(updatedUsers);
            updatedUsers.forEach((user) => {
              db.collection('/users').doc(user.objectID).update(user);
            });*/
          

            
            /*
            // Analisis de pedidos de cena
            const data = [];
            await db.collection('/evolbe-modules/restaurant/orders').where('datePurchased', '>=', 1676678400000).get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })
            });
            
            const cuenta = [];
            data.forEach(element => {
                const newElement = JSON.parse(JSON.stringify(element));

                const hora = new Date(newElement.datePurchased).getHours();

      
                if(hora > 17 && newElement.delivery === true){
                    console.log('elemento', newElement);
                    console.log('entra',hora);

                    cuenta.push(newElement);
                }
            });

            console.log('total', cuenta.length);
            console.log('media', cuenta.length / 59);
            */
            
          

            // Actualizar usuarios (estandarizar usuarios)
            /*const data = [];
            await db.collection('/users').orderBy('birthDate.seconds', 'asc').get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })
            });

            data.forEach(element => {
                const newElement = JSON.parse(JSON.stringify(element));
                newElement.birthDate = element.birthDate.seconds * 1000;
                db.collection("/users").doc(element.objectID).update(newElement);
            });*/
          


            // Descargar Datos Pedidos
            /*const data = [];
            await db.collection('/evolbe-modules/restaurant/orders')
                .where('datePurchased', '>=', 1717200000000)
                .where('datePurchased', '<', 1719792000000)
                .where('status', '==', 4)
                //.where('customer.phone', '==', '651981437')
                .get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })
            });

            const newData = [];

            data.forEach(element => {
                const fechaC = format(new Date(element.datePurchased), 'dd/MM/yyyy')
                 
                const newElement = {
                    cliente: element.customer.lastName + ', ' + element.customer.firstName,
                    telefono: element.customer.phone,
                    email: element.customer.email,
                    direccion: element.customer.address?.street + ', ' + element.customer.address?.number + ', ' + element.customer.address?.specific + ', ' + element.customer.address?.zipCode + ', ' + element.customer.address?.city,
                    fechaCompra: fechaC,
                    delivery: element.delivery,
                    metodoPago: element.payment.method,
                    subtotal: element.subtotal,
                    recargo: element.surcharge,
                    envio: element.shipping,
                    total: element.total,
                    iva: element.tax
                };

                if(element.delivery === false){
                    newData.push(newElement); 
                }

            });

            console.log('newData',newData);

            //Crea un libro de trabajo de Excel
            const workbook = XLSX.utils.book_new();

            // Crea una hoja de trabajo
            const worksheet = XLSX.utils.json_to_sheet(newData);

            // Agrega la hoja de trabajo al libro de trabajo
            XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

            // Genera un archivo Excel y lo descarga
            XLSX.writeFile(workbook, "pedidos_junio.xlsx");*/
    

            // Recuento
            /*const data = [];
            await db.collection('/evolbe-modules/restaurant/orders')
                .where('datePurchased', '>=', 1704067200000)
                .where('datePurchased', '<', 1711929600000)
                .get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })
            });

            let count = 0;

            data.forEach(element => {
                element.items.forEach(item => {
                    if(item.name === 'Hamburguesa "Le Foie"'){
                        count = count + item.quantity;
                        console.log(count);
                    }
                    
                    if(item.type === 'PROMO'){
                        let promoCount = 0;
                        item.groups.forEach(group => {
                            group.articles.forEach(article => {
                                if(article.name === 'Hamburguesa "Le Foie"'){
                                    promoCount = promoCount + 1;
                                }
                            });
                        });
                        count = count + (promoCount * item.quantity)
                    }
                });
            });

            console.log('count',count);*/

            // Descargar Datos Clientes
            /* const data = [];
            await db.collection('/users').get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })
            });

            const newData = [];

            data.forEach(element => {
                let cumple = '';
                if (element.birthDate && !element.birthDate.seconds && !element.birthDate._seconds) {
                    cumple = format(new Date(element.birthDate), 'MM/dd/yyyy')
                }
                
                const newElement = {
                    email: element.email,
                    phone: '34' + element.phone,
                    fn: element.firstName,
                    ln: element.lastName,
                    ct: element.address?.city,
                    country: 'ES',
                    dob: cumple,
                    zip: element.address?.zipCode,
                    gen: element.gender?.toUpperCase()
                };

                // Solo los que aceptan publicidad
                if(element?.notifications?.email?.promotions || element?.notifications?.email){
                    newData.push(newElement);  
                }
            });

            console.log(newData);
            
            //Crea un libro de trabajo de Excel
            const workbook = XLSX.utils.book_new();

            // Crea una hoja de trabajo
            const worksheet = XLSX.utils.json_to_sheet(newData);

            // Agrega la hoja de trabajo al libro de trabajo
            XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

            // Genera un archivo Excel y lo descarga
            XLSX.writeFile(workbook, "clientes.xlsx");
            */
        
        /* const data = [];
                const users = ["2PFeMFYuAzPwKl6UKIF2XHiOCv32", "36ZZ63gkjdZYKSW5SBFRMNb1x443", "9AXpGZN4m5MJ1tmIuGoa9MCaSth1", "bBJvrMZ2s6c7QON0HCHODk3XGtH2", "bDm3vk6OxdWB2Dm3XgPJaS9A76p2", "bV26JyNgDUUIv6MnR3h0n0Qx2Ai1", "f4AnfdBNwbe1aMzHyueaMVwq6W92", "FEzI8i7jMKgUDyGc5s2ePmZaMw72", "FWAUnoIB9idXecEN8ckSjO5ER753", "fXwnj1KMg6PLDEyw2ONuq5Nk21p2", "Gh2x7Bm5YAZpfaxaAOvlLkDZybk1", "jjV2yBL3qEdSj1gGIvS4m7cyhon2", "k7iR2vZMEuYiYi4CPtncT1rGBi63", "jaQ9NjStYFNSS3CYlVVFdleBIC23", "N14NuXfpdEhI6zHTIFAa4OohvHv2", "olM5Iwk7WENPmFDlQwYub0gzmlh1", "oZE8MEYHK7Qkzm24GBIsn5uNYgk2", "RhvlkL50icN3wXFegqczQLTkXvI3", "rz706D1ofWZCLQx3wKVITeiCaYx1", "t11tJ9epW1ckgYSFdGROFCWyfWY2", "TgmaisRnPaeHnE9g58edq0khFGD2", "w2bg18caD2UkgyaiuJCeLVCoHjX2", "WwcwubvMEoa8lRR9QNyDwodV4qk2", "X8hCjNFZp1SkcXqZdREGWZ6EjQa2", "YaluAQ7TARc4ASm7obaYj2nxZoa2", "ZDagIRbdmGZrwrncP20GBwmXiyH2", "5co2klbfYlZ8MQ702MRfYmsA76n2", "AajwKmOVR9YsYwanE5RB58fyKVD2", "E5fnYIsVOtNxR3CuaL1lrzx6UB33", "eS8d3GAn95b4Vq8BpN7WXkdCSXw1", "kw3rMX51jFeGAsGrAjVrKSxVbRI2", "NaiW0fIpYqMUVqyKEjtgvgvIiBk1", "p2mpIaQXz6O39ovpfkUg5OTWNYt2", "u3SmbOv29pUOwtRpUe7DC2T7bZ53", "9NBYyr9d1YcFjG1IAlc3KZnQH9u1"]
            await db.collection('/users').get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })
            })
            .catch(err => {
                console.log(err);
            });

            let i = 0;
            users.forEach(element => {
                const user = data.find(x => x.objectID === element);
                if(user){
                    fetch('https://us-central1-docemasunorestaurante-com.cloudfunctions.net/sendSMS', { method: 'POST', body: JSON.stringify({
                        "message": 'Sabemos que ayer no pudiste disfrutar de nuestra promo 2x1 Vulcano, así que hoy en exclusiva para ti disfrútala utilizando este enlace: https://app.docemasunorestaurante.com/restaurant/cart?coupon=HALLOWEEN&user=' + user.uid,
                        "to": "+" + user.countryCode + user.phone,
                    })
                    }).then(() => {
                    console.log('mensaje enviado al', user.phone);
                    });
                    i = i + 1;
                }
            });

            console.log(i);
*/
/*
            const data = [];
            const users = ["DgY5PfnPJ1ZYzdntGcMKeBUBoXo2"]
            await db.collection('/users').get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })
            })
            .catch(err => {
                console.log(err);
            });

            users.forEach(element => {
                const user = data.find(x => x.objectID === element);
                if(user.uid === "DgY5PfnPJ1ZYzdntGcMKeBUBoXo2"){
                    const coupon = {
                    active: true,
                    customer: {
                        uid: user.uid,
                        phone: "654179061"
                    },
                    dateCreated: new Date().getTime(),
                    dateStart: new Date().getTime(),
                    dateEnd: 1861920000000,
                    description: "Descuento del 10% en tu pedido",
                    discount: 10,
                    images: [{
                        "512": "https://firebasestorage.googleapis.com/v0/b/docemasunorestaurante-com.appspot.com/o/evolbe-modules%2Fcoupons%2Fthumbnails%2Farreglo-raquetas-pelotas-tenis_512x512.webp?alt=media",
                    }],
                    minOrder: 1,
                    name: "PADELMIGIJON",
                    type: "%",
                    uses: 9999999,
                    };

                    console.log('coupon',coupon);
                    
    
                    //db.collection("/evolbe-modules/coupons/private").add(coupon);
                }
            });
*/
/*
                const baloncestoinmaculada = {
                active: true,
                customer: {
                    uid: 'Bmu5kB42kZYmYTuqR7MkgtC8bYg1'
                },
                dateCreated: new Date().getTime(),
                dateStart: new Date().getTime(),
                dateEnd: 1758301710000,
                description: "Descuento del 10% en tu pedido",
                discount: 10,
                images: [{
                    "512": "https://yt3.ggpht.com/ytc/AMLnZu9X24iZSM_T6Q0u25IcSIslWmF37ldSCx27rVWz=s900-c-k-c0x00ffffff-no-rj",
                }],
                minOrder: 0,
                name: "BALONCESTOINMACULADA",
                type: "%",
                uses: 99999999999999,
                };

                await db.collection("/evolbe-modules/coupons/private").add(baloncestoinmaculada);
*/
/*

            let promocion;
            await db.collection('/evolbe-modules/restaurant/promotions').doc('gPrkDE20FVbpVr6uaOzd').get().then(res => {
                promocion = res.data();
            })
            .catch(err => {
                console.log(err);
            });
            const newPromocion = JSON.parse(JSON.stringify(promocion));

            const data = [];
            await db.collection('/evolbe-modules/restaurant/dishes').get().then(res => {
                res.forEach(item => {
                    const e = {
                    objectID: item.id,
                    ...item.data()
                    }
                    data.push(e);
                })
            })
            .catch(err => {
                console.log(err);
            });

            const newElements = [];
            data.forEach(async (element,i) => {
                const newElement = JSON.parse(JSON.stringify(element));

                if(newElement.active){
                    if(newElement.category.name === 'Hamburguesas' && newElement.name !== 'Hamburguesa Infantil' ){
                        newElements.push(newElement);
                    } else if(newElement.category.name === 'Fajitas'){
                        newElements.push(newElement);
                    } else if(newElement.category.name === 'Sandwiches' && newElement.name !== 'Sandwich Mixto' && newElement.name !== 'Sandwich Mixto con huevo'){
                        newElements.push(newElement);
                    }
                }

            });

            newPromocion.groups[1].articles = newElements;

            console.log(newPromocion);

            //await db.collection('/evolbe-modules/restaurant/promotions').doc('gPrkDE20FVbpVr6uaOzd').set(newPromocion); */

            /*const data = [];
                await db.collection('/evolbe-modules/restaurant/dishes').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                .catch(err => {
                    console.log(err);
                });

                data.forEach(async (element,i) => {
                    const newElement = JSON.parse(JSON.stringify(element));

                    if(element.variations){
                        const newMofiers = []
                        newElement.variations.forEach(modifier => {
                            const newOptions = []
                            modifier.options.forEach(option => {
                                newOptions.push({
                                    name: option.text,
                                    price: option.increase,
                                })
                            });

                            newMofiers.push({
                                name: modifier.name,
                                type: 'radio',
                                options: newOptions,
                            });
                        });

                        newElement.modifiers = newMofiers;
                    }

                    delete newElement.variations;
                    
                    console.log(newElement);

                    await db.collection('/evolbe-modules/restaurant/dishes').doc(element.objectID).set(newElement);
                });*/

                // Actualizar promocion
                /*let data;
                    await db.collection('/evolbe-modules/restaurant/promotions').doc('3M7F3s8Tc46GalbXnVfo').get().then(res => {
                    data = res.data();

                    const newData = JSON.parse(JSON.stringify(data));
                    newData.type = "PROMO"
                    newData.groups = [];
                    delete newData.steps;
                    
                    data.steps.forEach(variation => {
                        const newVariation = JSON.parse(JSON.stringify(variation));
                        newVariation.type = "radio"
                        newVariation.articles = variation.dishes;
                        delete newVariation.dishes;
                        newData.groups.push(newVariation);
                    });
                    

                    console.log(newData);
                    //db.collection('/evolbe-modules/restaurant/promotions').doc('3M7F3s8Tc46GalbXnVfo').set(newData);
                })
*/
                /*
                
                 
                
                // Actualizar Orden
                let order = {};
                await db.collection('/evolbe-modules/restaurant/orders').doc('B8AiyVeP3FAZ2af6f55B').get().then(res => {
                    order = res.data();
                })
                const newOrder = JSON.parse(JSON.stringify(order));
                newOrder.update = true;
                console.log(newOrder);
                
                db.collection('/evolbe-modules/restaurant/orders').doc('B8AiyVeP3FAZ2af6f55B').set(newOrder);
                */
            }


    },
    getters: {
        isLogin(state){
            return !!state.user;
        },
    },
    modules: {},
});
