import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import { store } from '../store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/restaurant/home/'
  },
  {
    path: '/restaurant',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/home'
      },
      {
        path: 'home',
        name: 'HomePage',
        component: () => import('@/views/HomePage.vue')
      },
      {
        path: 'promotions',
        name: 'PromotionsPage',
        component: () => import('@/views/Promotions.vue')
      },
      {
        path: 'dishes',
        name: 'DishesPage',
        component: () => import('@/views/Dishes.vue')
      },
      {
        path: 'my-account',
        name: 'MyAccountPage',
        meta: {requiresAuth: true},
        component: () => import('@/views/my-account/MyAccount.vue'),
      },
      {
        path: 'cart',
        name: 'CartPage',
        component: () => import('@/views/checkout/Cart.vue'),
      },
    ]
  },

  /* SINGLE PAGE */
  {
    path: '/dishes/:id',
    name: 'SingleDish',
    component: () => import('@/views/single-page/Dish.vue'),
  },
  {
    path: '/promotions/:id',
    name: 'SinglePromotion',
    component: () => import('@/views/single-page/Promotion.vue'),
  },
  {
    path: '/rewards/:id',
    name: 'SingleReward',
    component: () => import('@/views/single-page/Reward.vue'),
    meta: {requiresAuth: true}
  },

  /* LOGIN */
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('@/views/login/Login.vue'),
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: () => import('@/views/login/Register.vue'),
  },
  {
    path: '/register-success',
    name: 'RegisterSuccessPage',
    component: () => import('@/views/login/RegisterSuccess.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/recover-password',
    name: 'RecoverPasswordPage',
    component: () => import('@/views/login/RecoverPassword.vue'),
  },
  {
    path: '/add-phone',
    name: 'AddPhonePage',
    component: () => import('@/views/login/AddPhone.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/verify-phone',
    name: 'VerifyPhonePage',
    component: () => import('@/views/login/VerifyPhone.vue'),
    meta: {requiresAuth: true}
  },

  /* SHOP */
  {
    path: '/checkout',
    name: 'CheckoutPage',
    component: () => import('@/views/checkout/Checkout.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/payment',
    name: 'PaymentPage',
    component: () => import('@/views/checkout/Payment.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/confirmation',
    name: 'ConfirmationPage',
    component: () => import('@/views/checkout/Confirmation.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/tracking/:id',
    name: 'TrackingPage',
    component: () => import('@/views/checkout/Tracking.vue'),
  },


  /* ACCOUNT */
  {
    path: '/my-account/my-orders',
    name: 'MyOrdersPage',
    component: () => import('@/views/my-account/MyOrders.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my-account/coupons',
    name: 'CouponsPage',
    component: () => import('@/views/my-account/Coupons.vue'),
    meta: {requiresAuth: true}
  },


    {
      path: '/my-account/my-points',
      name: 'MyPointsPage',
      component: () => import('@/views/my-account/MyPoints.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/my-account/achievements',
      name: 'AchievementsPage',
      component: () => import('@/views/my-account/Achievements.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/my-account/my-data',
      name: 'MyDataPage',
      component: () => import('@/views/my-account/MyData.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/my-account/my-address',
      name: 'MyAddressPage',
      component: () => import('@/views/my-account/MyAddress.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/my-account/billing',
      name: 'BillingPage',
      component: () => import('@/views/my-account/Billing.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/my-account/privacity',
      name: 'PrivacityPage',
      component: () => import('@/views/my-account/Privacity.vue'),
      meta: {requiresAuth: true}
    },
  {
    path: '/my-account/notifications',
    name: 'NotificationsPage',
    component: () => import('@/views/my-account/Notifications.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my-account/recommend-friend',
    name: 'RecommendFriendPage',
    component: () => import('@/views/my-account/RecommendFriend.vue'),
    meta: {requiresAuth: true}
  },

  { path: '/:catchAll(.*)',
    redirect: '/', }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  store.dispatch('loadLocalStorage').then(() => {
    if (to.meta.requiresAuth) {
      if (store.getters.isLogin) {
        next();
      } else{
        next('/register');
      }
    } else {
      if(to.path === '/login' || to.path === '/register'){
        if (store.getters.isLogin) {
          next('/restaurant/my-account');
        } else {
          next();
        }
      }
      next();
    }
  }, error => {
      console.log(error);
  })
});

router.afterEach(() => {
  setTimeout(() => {
    const el = document.getElementById("page-content");
    //console.log('el',el);
   
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    el.scrollByPoint(0,1,0); 

  }, 500);
});

export default router
